const GLOBS = require('../../../models/globals')
const RADAR_GLOBS = require('../globals')
const d3 = require('d3')

// const printRadar = require('../togglePanelFunctions/printRadar')

const mouseoverQuadrant = require('../pointer-events/mouseoverQuadrant')
const mouseoutQuadrant = require('../pointer-events/mouseoutQuadrant')
import selectQuadrant from '../pointer-events/selectQuadrant'

import createHomeLink from '../plotting/createHomeLink'
const drawLegend = require('../plotting/drawLegend')

const enforcePrintTheme = require('../visibilityChecks/enforcePrintTheme')
import checkToggleVisibility from '../visibilityChecks/checkToggleVisibility'

/**
 * Creates colored quadrant buttons near the top of the screen. Plus print and feedback buttons
 * Additionally, first row of toggle names and print theme toggles
 * @param {radar node} radarElement
 * @param {list of quadrants} quadrants
 * @param {*} group node to append to
 */
// let imageElement = ''
const createQuadrantButtons = (radarElement, quadrants, group) => {
  // const createQuadrantButtons = (radarElement, imageElement, quadrants, group) => {
  const toggleSettings = RADAR_GLOBS.toggleSettings

  var buttonsGroup = group.append('div')
    .classed('buttons-group', true)

  var quadrantButtons = buttonsGroup.append('div')
    .classed('quadrant-btn--group', true)

  var alternativeDiv = group.append('div')
    .attr('id', 'alternative-buttons')
  //set div for the images
  let headerImages = group.append('div').attr('class', 'detail-images')
  
  function addButton (quadrant) {
    radarElement
      .append('div')
      .attr('class', 'quadrant-table ' + quadrant.order)

    let imageContainer = headerImages.append('div').classed('detail-image',true).classed(quadrant.order,true)
    imageContainer.append('p').text(quadrant.quadrant.name().toUpperCase())
    imageContainer.append('img').attr('src', quadrant.quadrant.pcPath())

    quadrantButtons.append('div')
      .attr('class', 'button ' + quadrant.order + ' full-view')
      .classed('quadrant-btn', true)
      .style('background-color', quadrant.quadrant.color())
      .text(quadrant.quadrant.name())
      .on('mouseover', () => {
        if (toggleSettings.dragDropOn) {
          return
        }
        enforcePrintTheme(quadrant.order, 'mouseover')
        mouseoverQuadrant(quadrant.order)
      })
      .on('mouseout', () => {
        if (toggleSettings.dragDropOn) {
          return
        }
        enforcePrintTheme(quadrant.order, 'mouseout')
        mouseoutQuadrant(quadrant.order)
      })
      .on('click', () => {
        if (toggleSettings.dragDropOn) {
          return
        }
        const svg = RADAR_GLOBS.svg
        toggleSettings.zoomedView = true
        svg.selectAll('.home-link').classed('selected', false)
        createHomeLink(d3.select('header'))
        selectQuadrant(quadrant.order, quadrant.startAngle)
        //header images appearance control
        headerImages.selectAll('.detail-image').classed('selected', false)
        headerImages.select('.'+quadrant.order).classed('selected',true)

        // if (svg.select('.legend.legend-' + quadrant.order).empty()) {
        //   drawLegend(quadrant.order)
        // }
        if (toggleSettings.showBlipNames) {
          svg.selectAll('.zoomed-labels')
            .attr('visibility', 'visible')
        }
        enforcePrintTheme(quadrant.order, 'select')
      })
  }

  var quadrantNumbers = Array.from(new Array(GLOBS.QUADRANT_NAMES.length), (val, index) => index)

  _.each(quadrantNumbers, function (i) {
    addButton(quadrants[i])
  })

  buttonsGroup.append('div').classed('togglePanelContainer', true).attr('id', 'togglePanelContainer')
  /*
  d3.select('#togglePanelContainer').append('div').attr('id', 'togglePanelHeader').on('click', showTogglePanel).attr('title', 'Configuration')

  d3.select('#togglePanelHeader').append('svg').attr('id', 'hamburger_svg').attr('width', '25').attr('height', '25').attr('style', 'vertical-align: bottom;')
    .attr('viewBox', '0 0 20 20')
  d3.select('#hamburger_svg').append('path').attr('d', 'M15.808,14.066H6.516v-1.162H5.354v1.162H4.193c-0.321,0-0.581,0.26-0.581,0.58s0.26,0.58,0.581,0.58h1.162'
    + 'v1.162h1.162v-1.162h9.292c0.32,0,0.58-0.26,0.58-0.58S16.128,14.066,15.808,14.066z M15.808,9.419h-1.742V8.258h-1.162v1.161'
    + 'h-8.71c-0.321,0-0.581,0.26-0.581,0.581c0,0.321,0.26,0.581,0.581,0.581h8.71v1.161h1.162v-1.161h1.742'
    + 'c0.32,0,0.58-0.26,0.58-0.581C16.388,9.679,16.128,9.419,15.808,9.419z M17.55,0.708H2.451c-0.962,0-1.742,0.78-1.742,1.742v15.1'
    + 'c0,0.961,0.78,1.74,1.742,1.74H17.55c0.962,0,1.742-0.779,1.742-1.74v-15.1C19.292,1.488,18.512,0.708,17.55,0.708z M18.13,17.551'
    + 'c0,0.32-0.26,0.58-0.58,0.58H2.451c-0.321,0-0.581-0.26-0.581-0.58v-15.1c0-0.321,0.26-0.581,0.581-0.581H17.55'
    + 'c0.32,0,0.58,0.26,0.58,0.581V17.551z M15.808,4.774H9.419V3.612H8.258v1.162H4.193c-0.321,0-0.581,0.26-0.581,0.581'
    + 's0.26,0.581,0.581,0.581h4.065v1.162h1.161V5.935h6.388c0.32,0,0.58-0.26,0.58-0.581S16.128,4.774,15.808,4.774z')
    //.attr('style','transform: scale(0.04)')
    .attr('fill', 'black')
    .attr('stroke', 'black')
    .attr('stroke-width', '.3px')
  */

  d3.select('#togglePanelContainer').append('div').attr('id', 'togglePanel').attr('style', 'display: none;')

  function showTogglePanel () {
    var togglePanel = document.getElementById('togglePanel')

    if (togglePanel.style.display == 'none') {
      togglePanel.style.display = 'block'
      checkToggleVisibility()
      //    svg.selectAll('.blip-link').attr('visibility', 'visible')
    } else {
      togglePanel.style.display = 'none'
      //     svg.selectAll('.blip-link').attr('visibility', 'hidden')
    }
  }


}
export default createQuadrantButtons
