// labler simulated annealing algorithm: https://github.com/tinker10/D3-Labeler/
const labeler = function() {
    var lab = [],
        anc = [],
        dead = [],
        font = 11,
        w = 1, // box width
        h = 1, // box width
        labeler = {};
  
    var x_max_move = font * 1.2, //orig 5, 10 is good
        y_max_move = font * 1.5,
        max_angle = 0.5,
        acc = 0;
        rej = 0;
  
    // weights
    var w_len = 0.1, // leader line length 0.2 orig, there is no line
        w_inter = 0.0, // leader line intersection, 1.0 orig, there is no line
        w_lab2 = 50.0, // label-label overlap 30 orig
        w_lab_anc = 15.0; // label-anchor overlap
        w_orient = 3.0; // orientation bias
  
    // booleans for user defined functions
    var user_energy = false,
        user_schedule = false,
        user_ring = false;
  
    var user_defined_energy, 
        user_defined_schedule,
        user_defined_ring,
        user_defined_translate;
  
    var energy = function(index) {
    // energy function, tailored for label placement
  
        var m = lab.length, 
            ener = 0,
            dx = lab[index].x - anc[index].x,
            dy = anc[index].y - lab[index].y,
            dist = Math.sqrt(dx * dx + dy * dy),
            overlap = true,
            amount = 0
            theta = 0;
  
        // penalty for length of leader line
        if (dist > 0) ener += dist * w_len;
  
        // label orientation bias
        dx /= dist;
        dy /= dist;
        // if (dx > 0 && dy > 0) { ener += 0 * w_orient; } //right and above blip
        // else if (dx < 0 && dy > 0) { ener += 0 * w_orient; } //left and above blip
        // else if (dx > 0 && dy < 0) { ener += 2 * w_orient; } //right and below blip
        // else if (dx < 0 && dy < 0) { ener += 2 * w_orient; } //left and below blip
        // else { ener += 3 * w_orient; }
        //if (dy > 10 && dy < 10) { ener += 1 * w_orient; } //Penalty for being on the same y
  
        /**
         * y21
         *  |
         * y22
         *     x21----x22
         */
        var x21 = lab[index].x - lab[index].width/2.0 - 2.0,
            y21 = lab[index].y - lab[index].height - 2.0,
            x22 = lab[index].x + lab[index].width/2.0 + 2.0,
            y22 = lab[index].y + 2.0;
        var x11, x12, y11, y12, x_overlap, y_overlap, overlap_area;
        
        for (var i = 0; i < m; i++) {
          if (i != index) {
  
            // penalty for intersection of leader lines
            overlap = intersect(anc[index].x, lab[index].x, anc[i].x, lab[i].x,
                            anc[index].y, lab[index].y, anc[i].y, lab[i].y);
            if (overlap) ener += w_inter;
  
            // penalty for label-label overlap
            var x11 = lab[i].x - lab[i].width/2.0 - 2.0,
            y11 = lab[i].y - lab[i].height - 2.0,
            x12 = lab[i].x + lab[i].width/2.0 + 2.0,
            y12 = lab[i].y + 2.0;
  
            x_overlap = Math.max(0, Math.min(x12,x22) - Math.max(x11,x21));
            y_overlap = Math.max(0, Math.min(y12,y22) - Math.max(y11,y21));
            overlap_area = x_overlap * y_overlap;
            let overlapPenalty = 0
            if (overlap_area > 100) {
              overlapPenalty+= 70 
              if(y_overlap > 5) {
                overlapPenalty += 70 * y_overlap
                //lab[index].y -= lab.length < 65 ? 3 : 0
              }
            }
            ener += (overlap_area * w_lab2 + overlapPenalty);
            }
  
            // penalty for label-anchor overlap
            x11 = anc[i].x - anc[i].r;
            y11 = anc[i].y - anc[i].r;
            x12 = anc[i].x + anc[i].r;
            y12 = anc[i].y + anc[i].r;
            x_overlap = Math.max(0, Math.min(x12,x22) - Math.max(x11,x21));
            y_overlap = Math.max(0, Math.min(y12,y22) - Math.max(y11,y21));
            overlap_area = x_overlap * y_overlap;
            let overlapPenalty = overlap_area > 100 ? 60 : 0
            ener += (overlap_area * w_lab_anc + overlapPenalty);
        }
  
  
        //penalty for overlap of dead space
        for (let i = 0; i < dead.length; i++) {
          // penalty for label-label overlap
          x11 = dead[i].x - dead[i].width/2 - 2.0;
          y11 = dead[i].y - dead[i].height - 2.0;
          x12 = dead[i].x + dead[i].width/2 + 2.0;
          y12 = dead[i].y + 2.0;
          x_overlap = Math.max(0, Math.min(x12,x22) - Math.max(x11,x21));
          y_overlap = Math.max(0, Math.min(y12,y22) - Math.max(y11,y21));
          overlap_area = x_overlap * y_overlap;
          let overlapPenalty = 0
          if (overlap_area > 0) {
            overlapPenalty+= 50 
            if(y_overlap > 5) {
              overlapPenalty += y_overlap * 40
              //lab[index].y -= 2
            }
          }
          ener += (overlap_area * w_lab2 + overlapPenalty);
        }
  
        //penalty for overlap over ring
        // https://stackoverflow.com/questions/622287/area-of-intersection-between-circle-and-rectangle
        if (user_ring) {
          //helper functions
          const dist = function(x1, y1, x2, y2) {
            const d = Math.sqrt(Math.pow(x1-x2, 2) + Math.pow(y1-y2, 2))
            return d
          }
        
          /**
           * pt1: {x, y}
           * pt2: {x, y}
           */
          // https://mathworld.wolfram.com/Circle-LineIntersection.html
          const intersectionPoint = function(pt1, pt2) {
            const r = user_defined_ring
            const x1 = pt1.x
            const y1 = pt1.y
            const x2 = pt2.x
            const y2 = pt2.y
            let alongX = true;
            if (y1 != y2) {
              alongX = false
            }
            const dx = x2-x1
            const dy = y2-y1
            const D = x1 * y2 - x2 * y1
            const dr = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))
            const discriminant = Math.pow(r, 2) * Math.pow(dr, 2) - Math.pow(D, 2)
            if (discriminant < 0) { //should never happen
              return null
            } else if (discriminant == 0) {
              const x = (D * dy) / Math.pow(dr, 2)
              const y = (D * dx) / Math.pow(dr, 2)
              return {x, y}
            } else {
              const sgn = (a) => a < 0 ? -1 : 1
              const rtdisc = Math.sqrt(discriminant)
              let x = (D * dy + sgn(dy) * dx * rtdisc) / Math.pow(dr, 2)
              let y = -1 * (D * dx + Math.abs(dy) * rtdisc) / Math.pow(dr, 2)
              if (alongX) {
                if ((x > x1 && x < x2) || (x < x1 && x > x2)) {
                  return {x, y}
                }
              } else {
                if ((y > y1 && y < y2) || (y < y1 && y > y2)) {
                  return {x, y}
                }
              }
              x = (D * dy - sgn(dy) * dx * rtdisc) / Math.pow(dr, 2)
              y = -1 * (D * dx - Math.abs(dy) * rtdisc) / Math.pow(dr, 2)
              return {x, y}
            }
          }
  
          // https://mathworld.wolfram.com/CircularSegment.html
          const circularSegmentA = function(pt1, pt2) { 
            const d = dist(pt1.x, pt1.y, pt2.x, pt2.y)
            const r = user_defined_ring
            const theta = 2 * Math.asin(d/(2 * r))
            const sectA = theta/2 * r**2
            const triA = r * Math.cos(theta)/2 * d
            return sectA - triA
          }
  
          // https://math.stackexchange.com/questions/516219/finding-out-the-area-of-a-triangle-if-the-coordinates-of-the-three-vertices-are
          const triangleA = function(pt1, pt2, pt3) {
            return Math.abs(((pt2.x - pt1.x)*(pt3.y-pt1.y) - (pt3.x-pt1.x)*(pt2.y-pt1.y))/2)
          }
  
  
          const tx21 = x21-user_defined_translate
          const ty21 = y21-user_defined_translate
          const tx22 = x22-user_defined_translate
          const ty22 = y22-user_defined_translate
          /**
         * y21  0-----1
         *  |   |     |
         * y22  3-----2
         *     x21----x22
         * Also, we need to translate the numbers to 0,0 because the calculations make sense that way
         */
          const vertices = [
            {x: tx21, y: ty21},
            {x: tx22, y: ty21},
            {x: tx22, y: ty22},
            {x: tx21, y: ty22}
          ]
          // number of vertices that lie outside of the radar
          const outsideVertices = []
          // if the distance from center to vertex is greater than the radius, it lies outside the radar
          if (dist(tx21, ty21, 0, 0) > user_defined_ring) {
            outsideVertices.push(0)
          }
          if (dist(tx21, ty22, 0, 0) > user_defined_ring) {
            outsideVertices.push(3)
          }
          if (dist(tx22, ty21, 0, 0) > user_defined_ring) {
            outsideVertices.push(1)
          }
          if (dist(tx22, ty22, 0, 0) > user_defined_ring) {
            outsideVertices.push(2)
          }
          //area calculation
          //overlap_area refers to the area outside of the radar
          if (outsideVertices.length == 0) { // all vertices within
            overlap_area = 0
          } else if (outsideVertices.length == 1) { // 3 vertices within, 1 vertex outside: triangle - circular segment
            const v = outsideVertices[0]
            const pt1 = intersectionPoint(vertices[v], vertices[(v+1) % 4])
            const pt2 = intersectionPoint(vertices[v], vertices[(v+4-1) % 4])
            const triA =  triangleA(pt1, pt2, vertices[v])
            const circularSegA = circularSegmentA(pt1, pt2)
            overlap_area = triA - circularSegA
          } else if (outsideVertices.length == 2) { // 2 vertices within, 2 vertex outside: triangle + triangle - circ seg
            let v1 = outsideVertices[0] // v1 is the one that increments
            let v2 = outsideVertices[1] // v2 is the one that decrements
            if ((v1+1)%4 == v2) {
              let temp = v1
              v1 = v2
              v2 = temp
            }
            const pt1 = intersectionPoint(vertices[v1], vertices[(v1+1) % 4])
            const pt2 = intersectionPoint(vertices[v2], vertices[(v2+4-1) % 4])
            const circularSegA = circularSegmentA(pt1, pt2)
            const triA1 = triangleA(pt1, pt2, vertices[v1])
            const triA2 = triangleA(vertices[v1], vertices[v2], pt2)
            overlap_area = triA1 + triA2 - circularSegA
          } else if (outsideVertices.length == 3) { // 1 vertex within, 3 vertices outside: rectangle - triangle - circ seg
            let v = 0
            for (let i = 0; i < 4; i ++) {
              if (!outsideVertices.includes(i)) {
                v = i
                break;
              }
            }
            const pt1 = intersectionPoint(vertices[v], vertices[(v+1) % 4])
            const pt2 = intersectionPoint(vertices[v], vertices[(v+4-1) % 4])
            const triA =  triangleA(pt1, pt2, vertices[v])
            const circularSegA = circularSegmentA(pt1, pt2)
            overlap_area = Math.abs(x22-x21) * Math.abs(y22-y21) - triA - circularSegA
          } else if (outsideVertices.length == 4) { 
            // all vertices outside radar, rarely happens, but if a bug ever shows up because a label lands exactly at the bottom, this is why
            overlap_area = 0
          }
          ener += overlap_area * 1000
        }
        return ener;
    };
  
    var mcmove = function(currT) {
    // Monte Carlo translation move
  
        // select a random label
        var i = Math.floor(Math.random() * lab.length); 
  
        // save old coordinates
        var x_old = lab[i].x;
        var y_old = lab[i].y;
  
        // old energy
        var old_energy;
        if (user_energy) {old_energy = user_defined_energy(i, lab, anc)}
        else {old_energy = energy(i)}
  
        // random translation
        const dx = (Math.random() - 0.5) * x_max_move
        const dy = (Math.random() - 0.5) * y_max_move
        lab[i].x += dx;
        lab[i].y += dy;
  
        // hard wall boundaries
        if (lab[i].x > w) lab[i].x = x_old;
        if (lab[i].x < 0) lab[i].x = x_old;
        if (lab[i].y > h) lab[i].y = y_old;
        if (lab[i].y < 0) lab[i].y = y_old;
  
        // new energy
        var new_energy;
        if (user_energy) {new_energy = user_defined_energy(i, lab, anc)}
        else {new_energy = energy(i)}
  
        // delta E
        var delta_energy = new_energy - old_energy;
  
        if (Math.random() < Math.exp(-delta_energy / currT)) {
          acc += 1;
        } else {
          // move back to old coordinates
          lab[i].x = x_old;
          lab[i].y = y_old;
          rej += 1;
        }
  
    };
  
    var mcrotate = function(currT) {
    // Monte Carlo rotation move
  
        // select a random label
        var i = Math.floor(Math.random() * lab.length); 
  
        // save old coordinates
        var x_old = lab[i].x;
        var y_old = lab[i].y;
  
        // old energy
        var old_energy;
        if (user_energy) {old_energy = user_defined_energy(i, lab, anc)}
        else {old_energy = energy(i)}
  
        // random angle
        var angle = (Math.random() - 0.5) * max_angle;
  
        var s = Math.sin(angle);
        var c = Math.cos(angle);
  
        // translate label (relative to anchor at origin):
        lab[i].x -= anc[i].x
        lab[i].y -= anc[i].y
  
        // rotate label
        var x_new = lab[i].x * c - lab[i].y * s,
            y_new = lab[i].x * s + lab[i].y * c;
  
        // translate label back
        lab[i].x = x_new + anc[i].x
        lab[i].y = y_new + anc[i].y
  
        // hard wall boundaries
        // if (lab[i].x + lab[i].width > w) lab[i].x = w-lab[i].width; // if its past the right, put it on the right edge
        // if (lab[i].x < 0) lab[i].x = 0; // if its past the left, put it on the left edge
        // if (lab[i].y > h) lab[i].y = h; // if its past the bottom, put it on the bottom edge
        // if (lab[i].y - lab[i].height < 0) lab[i].y = lab[i].height; // if its past the top, put it on the top edge
        if (lab[i].x > w) lab[i].x = x_old;
        if (lab[i].x < 0) lab[i].x = x_old;
        if (lab[i].y > h) lab[i].y = y_old;
        if (lab[i].y < 0) lab[i].y = y_old;
  
        // new energy
        var new_energy;
        if (user_energy) {new_energy = user_defined_energy(i, lab, anc)}
        else {new_energy = energy(i)}
  
        // delta E
        var delta_energy = new_energy - old_energy;
  
        if (Math.random() < Math.exp(-delta_energy / currT)) {
          acc += 1;
        } else {
          // move back to old coordinates
          lab[i].x = x_old;
          lab[i].y = y_old;
          rej += 1;
        }
        
    };
  
    var intersect = function(x1, x2, x3, x4, y1, y2, y3, y4) {
    // returns true if two lines intersect, else false
    // from http://paulbourke.net/geometry/lineline2d/
  
      var mua, mub;
      var denom, numera, numerb;
  
      denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
      numera = (x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3);
      numerb = (x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3);
  
      /* Is the intersection along the the segments */
      mua = numera / denom;
      mub = numerb / denom;
      if (!(mua < 0 || mua > 1 || mub < 0 || mub > 1)) {
          return true;
      }
      return false;
    }
  
    var cooling_schedule = function(currT, initialT, nsweeps) {
    // linear cooling
      return (currT - (initialT / nsweeps));
    }
  
    labeler.start = function(nsweeps) {
    // main simulated annealing function
        var m = lab.length,
            currT = 1.0,
            initialT = 1.0;
  
        for (var i = 0; i < nsweeps; i++) {
          for (var j = 0; j < m; j++) { 
            if (Math.random() < 0.5) { mcmove(currT); }
            else { mcrotate(currT); }
          }
          currT = cooling_schedule(currT, initialT, nsweeps);
        }
    };
  
    labeler.width = function(x) {
    // users insert graph width
      if (!arguments.length) return w;
      w = x;
      return labeler;
    };
  
    labeler.height = function(x) {
    // users insert graph height
      if (!arguments.length) return h;
      h = x;    
      return labeler;
    };
  
    labeler.label = function(x) {
    // users insert label positions
      if (!arguments.length) return lab;
      lab = x;
      return labeler;
    };
  
    labeler.anchor = function(x) {
    // users insert anchor positions
      if (!arguments.length) return anc;
      anc = x;
      return labeler;
    };
  
    labeler.dead = function(x) {
    // users insert dead positions
      if(!arguments.length) return dead
      dead = x
      return labeler
    }
  
    labeler.ring = function(x) {
      // users insert ring
        if(!arguments.length) return null
        user_defined_ring = x.r
        user_defined_translate = x.t
        user_ring = true
        return labeler
      }
  
    labeler.fontSize = function(x) {
      // users insert font size for max move calc
      if(!arguments.length) return font
      font = x
      return labeler
    }
  
    labeler.alt_energy = function(x) {
    // user defined energy
      if (!arguments.length) return energy;
      user_defined_energy = x;
      user_energy = true;
      return labeler;
    };
  
    labeler.alt_schedule = function(x) {
    // user defined cooling_schedule
      if (!arguments.length) return  cooling_schedule;
      user_defined_schedule = x;
      user_schedule = true;
      return labeler;
    };
  
    return labeler;
  };

  module.exports = labeler