const { rest } = require('lodash')
const sanitizeHtml = require('sanitize-html')
const _ = {
  forOwn: require('lodash/forOwn')
}

// TODO: extend allowed Input for description
const InputSanitizer = function () {
  var relaxedOptions = {
    allowedTags: ['b','i','em','strong','a','li','ol','ul','dl','dd','dt',
    'br','u','img','set','span','div','pre','td','th','tr','tbody','tfoot','table', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    allowedAttributes: {
      'a': ['href','target','rel','href', 'style'],
      'img': ['src','width','height','alt', 'style'],
      'div': ['class','style'],
      'span': ['class', 'style'],
      'ol': ['type','start', 'style'],
      'ul': ['style'],
      'p': ['style'],
      'th': ['align','char','charoff','valign', 'style'],
      'td': ['align','char','charoff','valign', 'style'],
      'tr': ['align','char','charoff','valign', 'style'],
      'tbody': ['align','char','charoff','valign', 'style'],
      'tfoot': ['align','char','charoff','valign', 'style'],
    },
    allowedSchemesByTag: {
      img: ['data']
    }
  }

  var restrictedOptions = {
    allowedTags: [],
    allowedAttributes: {},
    textFilter: function (text) {
      return text.replace(/&amp;/, '&')
    }
  }

  function trimWhiteSpaces (blip) {
    var processedBlip = {}
    _.forOwn(blip, function (value, key) {
      if(value !== undefined){
        processedBlip[key.trim()] = (value + '').trim()
      }
      else{
        processedBlip[key.trim()] = ''
      }
    })
    return processedBlip
  }

  var self = {}
  self.sanitize = function (rawBlip) {
    var blip = trimWhiteSpaces(rawBlip)
    blip.description = sanitizeHtml(blip.description, relaxedOptions) // Disabled to allow all kind of description formatting
    blip.name = sanitizeHtml(blip.name, restrictedOptions)
    //blip.isNew = sanitizeHtml(blip.isNew, restrictedOptions)
    blip.type = sanitizeHtml(blip.type, restrictedOptions)
    blip.ring = sanitizeHtml(blip.ring, restrictedOptions)
    blip.quadrant = sanitizeHtml(blip.quadrant, restrictedOptions)
    blip.marker = sanitizeHtml(blip.marker, restrictedOptions)
    blip.relevance = sanitizeHtml(blip.relevance, restrictedOptions)
    blip.speed = sanitizeHtml(blip.speed, restrictedOptions)
    blip.knowhow = sanitizeHtml(blip.knowhow, restrictedOptions)
    blip.id = sanitizeHtml(blip.id, restrictedOptions)
    //blip.filter = blip.filter
    return blip
  }

  self.sanitizeForProtectedSheet = function (rawBlip, header) {
    var blip = trimWhiteSpaces(rawBlip)

    const descriptionIndex = header.indexOf('description')
    const nameIndex = header.indexOf('name')
    //const isNewIndex = header.indexOf('isNew')
    const typeIndex = header.indexOf('type')
    const quadrantIndex = header.indexOf('quadrant')
    const ringIndex = header.indexOf('ring')
    const markerIndex = header.indexOf('marker')
    const relevanceIndex = header.indexOf('relevance')
    const speedIndex = header.indexOf('speed')
    const knowHowIndex = header.indexOf('knowhow_need')

    const description = descriptionIndex === -1 ? '' : blip[descriptionIndex]
    const name = nameIndex === -1 ? '' : blip[nameIndex]
    //const isNew = isNewIndex === -1 ? '' : blip[isNewIndex]
    const type = typeIndex === -1 ? '' : blip[typeIndex]
    const ring = ringIndex === -1 ? '' : blip[ringIndex]
    const quadrant = quadrantIndex === -1 ? '' : blip[quadrantIndex]
    const marker = markerIndex === -1 ? '' : blip[markerIndex]
    const relevance = relevanceIndex === -1? '' : blip[relevanceIndex]
    const speed = speedIndex === -1? '' : blip[speedIndex]
    const knowhow = knowHowIndex === -1? '' : blip[knowHowIndex]

    blip.description = sanitizeHtml(description, relaxedOptions)
    blip.name = sanitizeHtml(name, restrictedOptions)
    //blip.isNew = sanitizeHtml(isNew, restrictedOptions)
    blip.type = sanitizeHtml(type, restrictedOptions)
    blip.ring = sanitizeHtml(ring, restrictedOptions)
    blip.quadrant = sanitizeHtml(quadrant, restrictedOptions)
    blip.marker = sanitizeHtml(marker, restrictedOptions)
    blip.relevance = sanitizeHtml(relevance, restrictedOptions)
    blip.speed = sanitizeHtml(speed, restrictedOptions)
    blip.knowhow = sanitizeHtml(knowhow, restrictedOptions)

    return blip
  }

  return self
}

module.exports = InputSanitizer
