import $ from "jquery";
const GLOBS = require("../../../models/globals");
const GLOBS_RADAR = require("../globals");
const d3 = require("d3");
import request from "@/utils/request/index";

const Chance = require("chance");

const mathUtil = require("../util/mathUtil");
const createBlipShapes = require("../svg-paths/blipShapes");
const Gauge = require("../../../graphing/gauge");
const orderToNumber = require("../util/orderNumberUtil").orderToNumber;

const plotBlips = (quadrantGroup, quadrantWrapper) => {
  var blips, quadrant, startAngle, order;
  const svg = GLOBS_RADAR.svg;
  const ringCalculator = GLOBS_RADAR.ringCalculator;
  const rings = GLOBS_RADAR.ringsGlobal;

  quadrant = quadrantWrapper.quadrant;
  console.log('quadrant', quadrant.name())
  startAngle = quadrantWrapper.startAngle;
  console.log('startAngle: ', startAngle)
  order = quadrantWrapper.order;

  const center = () => {
    return GLOBS.RADAR_SIZE / 2
  }

  d3.select(".quadrant-table." + order)
    .append("h2")
    .attr("class", "quadrant-table__name")
    .text(quadrant.name());

  blips = quadrant.blips();

  const blipCoordinatesQuadrantDict = new Map(); // BLIPNAMES
  const anchor_array = [];
  const label_array = [];
  let searchedBlip = {};
  rings.forEach(function (ring, i) {
    var ringBlips = blips.filter(function (blip) {
      return blip.ring() === ring;
    });

    if (ringBlips.length === 0) {
      return;
    }

    var maxRadius, minRadius;

    minRadius = ringCalculator.getRadius(i);
    maxRadius = ringCalculator.getRadius(i + 1);

    var sumRing = ring
      .name()
      .split("")
      .reduce(function (p, c) {
        return p + c.charCodeAt(0);
      }, 0);
    var sumQuadrant = quadrant
      .name()
      .split("")
      .reduce(function (p, c) {
        return p + c.charCodeAt(0);
      }, 0);
    let chance = new Chance(
      Math.PI *
        sumRing *
        ring.name().length *
        sumQuadrant *
        quadrant.name().length
    );

    const ringList = addRing(ring.name(), order);
    const allBlipCoordinatesInRing = [];
    let blipCoordinatesRingDict = new Map(); // BLIPNAMES
    const blipShapes = createBlipShapes(svg);
    
      ringBlips.forEach(function (blip) {
        console.log('blip.location()): ', blip.location())
        let coordinates
        if (blip.location()) {
          const location = JSON.parse(blip.location());

          const distance = Math.round(
              ((maxRadius - minRadius) * location.distance) / 100 +
                  minRadius
          );
          const angel = Math.round(
              (360 + startAngle - 360 / GLOBS.QUADRANT_NAMES.length) % 360 +
                  ((360 / GLOBS.QUADRANT_NAMES.length) *
                      location.angel) /
                      100
          );
          let x = Math.abs(
              Math.sin((angel * Math.PI) / 180) * distance
          );
          let y = Math.abs(
              Math.cos((angel * Math.PI) / 180) * distance
          );

          if (angel <= 90) {
              //第一象限
              x = center() + x;
              y = center() - y;
          } else if (angel <= 180) {
              x = center() + x;
              y = center() + y;
          } else if (angel <= 270) {
              x = center() - x;
              y = center() + y;
          } else {
              x = center() - x;
              y = center() - y;
          }

          coordinates = [x, y];
      } else {
        coordinates = findBlipCoordinates(
          blip,
          chance,
          minRadius,
          maxRadius,
          startAngle,
          allBlipCoordinatesInRing
        );
      }
      
      blipCoordinatesRingDict.set(blip, coordinates); // BLIPNAMES
      allBlipCoordinatesInRing.push(coordinates);
      const blipData = drawBlipInCoordinates(
        blip,
        coordinates,
        quadrantWrapper,
        quadrantGroup,
        ringList,
        blipShapes
      );

      if (blipData !== undefined) {
        label_array.push(blipData[0]);
        anchor_array.push(blipData[1]);
        if (blipData.length === 3) searchedBlip = blipData[2];
      }
    });
    blipCoordinatesQuadrantDict.set(ring.name(), blipCoordinatesRingDict); // BLIPNAMES
  });
  return [blipCoordinatesQuadrantDict, label_array, anchor_array, searchedBlip];
};

function addRing(ring, order) {
  var table = d3.select(".quadrant-table." + order);
  table.append("h3").attr("class", "ring-name").text(ring);

  return table.append("ul").attr("class", "blip-ul");
}

function findBlipCoordinates(
  blip,
  chance,
  minRadius,
  maxRadius,
  startAngle,
  allBlipCoordinatesInRing
) {
  const dead_array = GLOBS_RADAR.dead_array;
  // const size = GLOBS_RADAR.size
  const maxIterations = 200; // TODO : change this?

  var coordinates = calculateBlipCoordinates(
    blip,
    chance,
    minRadius,
    maxRadius,
    startAngle
  );
  var iterationCounter = 0;
  var foundAPlace = false;

  while (iterationCounter < maxIterations) {
    if (
      thereIsCollision(blip, coordinates, allBlipCoordinatesInRing, dead_array)
    ) {
      coordinates = calculateBlipCoordinates(
        blip,
        chance,
        minRadius,
        maxRadius,
        startAngle
      );
    } else {
      foundAPlace = true;
      break;
    }
    iterationCounter++;
  }

  if (!foundAPlace && blip.width > GLOBS.MIN_BLIP_WIDTH) {
    blip.width = blip.width - 1;
    return findBlipCoordinates(
      blip,
      chance,
      minRadius,
      maxRadius,
      startAngle,
      allBlipCoordinatesInRing
    );
  } else {
    return coordinates;
  }
}

function thereIsCollision(blip, coordinates, allCoordinates, dead_array) {
  const x = coordinates[0];
  const y = coordinates[1];
  for (let i = 0; i < dead_array.length; i++) {
    /**
     * 0,0 up and to the left
     * x1       x2
     * ----------- y1
     * |         |
     * |         |
     * -----o----- y2
     *    (x,y)
     */
    const x1 = dead_array[i].x - dead_array[i].width / 2 - 2.0 - blip.width / 2;
    const y1 = dead_array[i].y - dead_array[i].height - blip.width / 2;
    const x2 = dead_array[i].x + dead_array[i].width / 2 + 2.0 + blip.width / 2;
    const y2 = dead_array[i].y + 2.0 + blip.width / 2;
    if (x > x1 && x < x2) {
      if (y > y1 && y < y2) {
        return true;
      }
    }
  }
  return allCoordinates.some(function (currentCoordinates) {
    return (
      Math.abs(currentCoordinates[0] - x) < blip.width &&
      Math.abs(currentCoordinates[1] - y) < blip.width
    );
  });
}

function calculateBlipCoordinates(
  blip,
  chance,
  minRadius,
  maxRadius,
  startAngle
) {
  startAngle -= GLOBS.QUADRANT_SIZE;
  var radius = chance.floating({
    min: minRadius + blip.width / 2,
    max: maxRadius - blip.width / 2,
  });

  var radius = radius < 40 ? radius + GLOBS.CENTER_OFFSET : radius;

  var angleDelta = mathUtil.toDegree(Math.asin(blip.width / 2 / radius));

  angleDelta =
    angleDelta > GLOBS.QUADRANT_SIZE / 2 ? GLOBS.QUADRANT_SIZE / 2 : angleDelta;

  var angle = mathUtil.toRadian(
    chance.integer({
      min: angleDelta,
      max: GLOBS.QUADRANT_SIZE - angleDelta,
    })
  );

  angle = angle + mathUtil.toRadian(startAngle + GLOBS.POLAR_OFFSET);

  var x = mathUtil.center() + radius * Math.cos(angle);
  var y = mathUtil.center() + radius * Math.sin(angle);

  return [x, y];
}

function drawBlipInCoordinates(
  blip,
  coordinates,
  quadrantWrapper,
  quadrantGroup,
  ringList,
  blipShapes
) {
  if (GLOBS.FILTERS.BLIPSNewFilter.length > 0) {
    if (!blip.filter()) return;

    var thisBlipFilter = "" + blip.filter().toLowerCase();
    var hasFilter = false;

    GLOBS.FILTERS.BLIPSNewFilter.forEach((filterStr) => {
      if (thisBlipFilter.includes(filterStr.toLowerCase())) {
        hasFilter = true;
      }
    });

    if (!hasFilter) {
      return;
    }
  }

  const svg = GLOBS_RADAR.svg;
  const tip = GLOBS_RADAR.tip;
  const legendKeysMap = GLOBS_RADAR.legendKeysMap;
  const searchString = GLOBS_RADAR.searchString;
  var x = coordinates[0];
  var y = coordinates[1];
  let order = quadrantWrapper.order;
  let startAngle = quadrantWrapper.startAngle;
  let quadrantNumber = orderToNumber()[order];

  var group = quadrantGroup
    .append("g")
    .attr("class", "blip-link")
    .attr("id", "blip-link-" + blip.number());

  // draw blip shape according to type
  var shapeIndex = legendKeysMap.get(blip.type());
  blipShapes[shapeIndex < blipShapes.length ? shapeIndex : 0](
    blip,
    x,
    y,
    order,
    group,
    quadrantWrapper.quadrant.color()
  );

  // Draw Blib Number
  group
    .append("text")
    .attr("x", x)
    .attr("y", y + blip.width / 5)
    .attr("class", "blip-text")
    // derive font-size from current blip width
    .style("font-size", blip.width / 2 + "px")
    .text(blip.number());

  var blipListItem = ringList.append("li");
  var blipText = blip.number() + ". " + blip.name();
  blipListItem
    .append("div")
    .attr("class", "blip-list-item")
    .attr("id", "blip-list-item-" + blip.number())
    .text(blipText)
    .style("font-weight", blip.marker() == 1 ? "bold" : "normal");

  var blipItemDescription = blipListItem
    .append("div")
    .attr("id", "blip-description-" + blip.number())
    .attr("class", "blip-item-description");
  blipItemDescription.append("p").attr("class", "p" + blip.number());

  // Show / Hide D3 Tooltips over Blips
  var mouseOver = function () {
    svg.selectAll("g.blip-link").attr("opacity", 0.3);
    group.attr("opacity", 1.0);
    //blipListItem.selectAll('.blip-list-item').classed('highlight', true)
    //tip.show(blip.name(), group.node())
  };

  var mouseOut = function () {
    svg.selectAll("g.blip-link").attr("opacity", 1.0);
    //blipListItem.selectAll('.blip-list-item').classed('highlight', false)
    //tip.hide().style('left', 0).style('top', 0)
  };

  blipListItem.on("mouseover", mouseOver).on("mouseout", mouseOut);
  group.on("mouseover", mouseOver).on("mouseout", mouseOut);
  group.on("click", function () {
    $("#" + this.id.replace("link", "list-item")).trigger("click");
    $("#" + this.id.replace("link", "list-item"))
      .parent()
      .trigger("click");
  });

  var clickBlip = function () {
    // MergeFlow API Call and Chart Drawing
    if (GLOBS.USE_MERGEFLOW) {
      GLOBS_RADAR.mergeFlowApi.makeAllApiCalls(blip, blipItemDescription);
    }
    const reqpath = process.env.VUE_APP_RADAR_TYPE;
    const dataAssigned = "data-assigned";
    if ($(blipItemDescription.node()).hasClass(dataAssigned)) {
      return;
    }

    blipItemDescription.select("p").html(blip.description());
    $(blipItemDescription.node()).addClass(dataAssigned);
    // request({
    //   url: "/" + reqpath + "/radar/technology/" + blip.id(),
    //   method: "GET",
    // }).then((res) => {
    //   blipItemDescription.select("p").html(res.description);
    //   $(blipItemDescription.node()).addClass(dataAssigned);
    // });
  };
  blipListItem.on("click", clickBlip);

  $(blipListItem.node())
    .find(".blip-list-item")
    .on("click", function (e) {
      let liEl = $(this).parent("li");
      e.preventDefault();
      let currentIsActive = liEl.hasClass("expanded");
      $(this)
        .parents(".quadrant-table.selected")
        .find("li")
        .removeClass("expanded");
      if (currentIsActive != 1) {
        liEl.addClass("expanded");
      }
    });

  if (searchString && blip.name().toLowerCase() == searchString.toLowerCase()) {
    // findSearchString(searchBlip,searchBlipItemDescription,searchStartAngle, searchBlipOrder)
    return [
      // label array
      {
        x,
        y,
        name: blip.name(),
        number: blip.number(),
        width: 0.0,
        height: 0.0,
        quadrant: order,
        num: quadrantNumber /* angle: startAngle- 360.0/radar.quadrants().length /*onFocus: onFocus, onFocusLost: onFocusLost */,
      },
      // anchor array
      { x, y: y, r: (blip.width / 5) * 3, quadrant: quadrantNumber },
      {
        searchBlipObj: blip,
        searchBlipItemDescription: blipItemDescription,
        searchStartAngle: startAngle,
        searchBlipOrder: order,
      },
    ];
  }

  return [
    // label array
    {
      x,
      y,
      name: blip.name(),
      number: blip.number(),
      width: 0.0,
      height: 0.0,
      quadrant: order,
      num: quadrantNumber /* angle: startAngle- 360.0/radar.quadrants().length /*onFocus: onFocus, onFocusLost: onFocusLost */,
    },
    // anchor array
    { x, y: y, r: (blip.width / 5) * 3, quadrant: quadrantNumber },
  ];
}

export default plotBlips;
