const { scaleDiverging } = require('d3')
const GLOBS = require('../models/globals')
const Blip = function (id, name, ring, type, description, marker, relevance, speed, knowhow, filter, location) {
  var self, number
  

  self = {}
  number = -1
  var apiResponses = new Map()

  self.width = GLOBS.IDEAL_BLIP_WIDTH

  

  self.name = function () {
    return name
  }

  self.description = function () {
    return description || ''
  }

  self.ring = function () {
    return ring
  }

  self.number = function () {
    return number
  }

  self.setApiResponses = function (apiMethod, response) {
    apiResponses.set(apiMethod, response)
  }

  self.apiResponses = function () {
    return apiResponses
  }

  self.setNumber = function (newNumber) {
    number = newNumber
  }

  self.type = function () {
    return type
  }

  self.marker = function () {
    return marker
  }

  self.setType = function(newtype){
    type = newtype
  }

  self.relevance = function(){
    return relevance;
  }

  self.setRelevance = function(newRelevance){
    relevance = newRelevance
  }

  self.speed =function(){
    return speed;
  }

  self.setSpeed = function(newSpeed){
    speed = newSpeed
  }

  self.knowhow = function(){
    return knowhow;
  }

  self.setKnowHow = function(newKnowHow){
    knowhow = newKnowHow
  }

  self.filter = function(){
    return filter;
  }

  self.setfilter = function(newfilter){
    filter = newfilter
  }

  self.id = function () {
    return id
  }

  self.location = function () {
    return location;
  }; 
  

  return self
}

module.exports = Blip
