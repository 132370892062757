const RADAR_GLOBS = require('../globals')

/**
 * resizing all fontsizes on radar, triggered whenever a slider is adjusted
 * @param {number} fontSize 
 * @param {'blip', 'ring', 'quadrant'} type 
 */
const resizeNames = (fontSize, type) => {
  const svg = RADAR_GLOBS.svg
  const toggleSettings = RADAR_GLOBS.toggleSettings
    // need to delete old names before creating the new ones.
    switch(type){
      case 'blip' : { // for blips -> blip-name
        svg.selectAll('.blip-textContainer .blip-name').style('font-size', fontSize+'px')
        break }
      case 'ring' : { // for rings -> ring-text
        svg.selectAll('text.ring-text').style('font-size', fontSize + 'px')
        toggleSettings.needToRedrawBlips = true
        break 
      }
      case 'quadrant' : { // quadrants -> arc-text / quadrant-text
        svg.selectAll('text.quadrant-text').style('font-size', fontSize + 'px')
        svg.selectAll('text.quadrant-text textPath').style('font-size', fontSize + 'px')
        // for (let [quadrantGroup, quadrant] of quadrantGroupGlobal) {
        //   quadrantGroup.append("text")
        //   .attr("class", "quadrant-text")
        //   .attr("dy", -GLOBS.RADAR_SIZE / 200)
        //   .attr("dx", GLOBS.RADAR_SIZE / 35)
        //   .append("textPath") //append a textPath to the text element
        //   .attr("xlink:href", "#arc_outer." + quadrant.order) //place the ID of the path here
        //   .attr("class", "arc-text")
        //   .attr("startOffset", "70%")
        //   .style('font-size', fontSize + 'px')
        //   .text(quadrant.quadrant.name);
        // }
        break
      }
    }
  }

  module.exports = resizeNames