const GLOBS = require('../../../models/globals')
const RADAR_GLOBS = require('../globals')
const d3 = require('d3')
const toRadian = require('../util/mathUtil').toRadian
import checkToggleVisibility from '../visibilityChecks/checkToggleVisibility'

/**
 * zooms into a quadrant
 * @param {'first', 'second', etc} order 
 * @param {number} startAngle 
 */
const selectQuadrant = function(order, startAngle) {  
    const svg = RADAR_GLOBS.svg
    const size = RADAR_GLOBS.size
    //d3.select('#toggleDragDrop').style('display', 'block').css('pointer-events', 'auto').css('opacity', '1')
    checkToggleVisibility()
    // $('div#sliderDiv_ring').css('pointer-events', 'none').css('opacity', '0.4')
    // $('div#toggleDiv_outsideLabels').css('pointer-events', 'none').css('opacity', '0.4')
    svg.selectAll('text.ring-text').attr('visibility', 'hidden')

    d3.selectAll('.button').classed('selected', false).classed('full-view', false)
    d3.selectAll('.button.' + order).classed('selected', true)
    d3.selectAll('.quadrant-table').classed('selected', false)
    d3.selectAll('.quadrant-table.' + order).classed('selected', true)
    svg.selectAll('.blip-item-description').classed('expanded', false)

    d3.select('body').classed('detail-view', true)

    let scale = 1;
    let halfSize = size / 2;
    startAngle = (90 - startAngle + 360) % 360;
    let endAngle = (startAngle + GLOBS.QUADRANT_SIZE + 360) % 360;
    let posXAxisCrossed = (startAngle >= 270 && endAngle <= 90) ? 1 : 0;
    let negXAxisCrossed = (startAngle >= 90 && startAngle < 180 && endAngle > 180 && endAngle <= 270) ? -1 : 0;
    startAngle = toRadian(startAngle);
    endAngle = toRadian(endAngle);
    let maxX = Math.max(0, posXAxisCrossed, Math.cos(startAngle), Math.cos(endAngle)) * halfSize;
    let minX = Math.min(0, negXAxisCrossed, Math.cos(startAngle), Math.cos(endAngle)) * halfSize;
    let translateX = halfSize - maxX - (halfSize - maxX + minX) / 2;
    let translateY = Math.max(0, Math.sin(startAngle), Math.sin(endAngle)) * halfSize - halfSize;

    if (window.innerWidth >= 1024) {
        translateX -= 30;
    }
    let translateXAll = translateX + halfSize;
    let translateYAll = translateY + halfSize;

    if (window.innerWidth < 1024) {
        let vbx = halfSize, vbwh = halfSize;
        if (window.innerWidth >= 600) {
            vbx *= 0.8;
            vbwh = vbx * 2;
        }
        svg.transition()
            .duration(GLOBS.ANIMATION_DURATION)
            .attr('viewBox', `${vbx} 0 ${vbwh} ${vbwh}`)
    }
    // animation from radar to quadrant view
    svg.select('.quadrant-group-' + order)
      .transition()
      .duration(GLOBS.ANIMATION_DURATION)
      .attr('transform', 'scale(' + scale + ') translate(' + translateX + ',' + translateY + ')')

    // moving the not relevant quadrants
    svg.selectAll('.quadrant-group:not(.quadrant-group-' + order + ')')
      .transition()
      .duration(GLOBS.ANIMATION_DURATION)
      .style('pointer-events', 'none')
      .attr('transform', 'translate(' + translateXAll + ',' + translateYAll + ') scale(0)')

    svg.selectAll('.quadrant-group')
      .style('pointer-events', 'auto')
    
    //deal with labels
    svg.selectAll('.blip-labels:not(.zoomed-labels)')
      .attr('visibility', 'hidden')
      .attr('pointer-events', 'none')
      .attr('transform', 'translate(' + translateXAll + ',' + translateYAll + ') scale(0)')
  }

 export default selectQuadrant