const Definitions = {

  // TODO: Manually Update Version in each Release
  RADAR_VERSION: 1.5,

  USE_MERGEFLOW: false,

  LOCK_View: false,
  
  //WEB-EAM URLs
  ROOT_URL_DEV: "https://auth-i.bmwgroup.net/auth/oauth2/realms/root/realms/intranetb2x/authorize?response_type=id_token&scope=openid%20groups&redirect_uri=",
  CLIENT_ID_DEV: "/&client_id=2d56e966-3a31-4b5f-9f6c-74c9d315bf08&nonce=",

  ROOT_URL_PROD: "https://auth.bmwgroup.net/auth/oauth2/realms/root/realms/intranetb2x/authorize?response_type=id_token&scope=openid%20groups&redirect_uri=",
  //TODO: Replace Client ID when Prod Web-eam config available
  CLIENT_ID_PROD: "/&client_id=4cd21d37-9758-4127-b7e3-c79955e520e0&nonce=", 

  WEB_EAM_LOCAL_TEST: "https://auth-i.bmwgroup.net/auth/oauth2/realms/root/realms/intranetb2x/authorize?response_type=id_token&scope=openid%20groups&redirect_uri=https://dev.techradar.eu-central-1.aws.cloud.bmw/&client_id=2d56e966-3a31-4b5f-9f6c-74c9d315bf08&nonce=",
  WEB_EAM_LOCAL_PROD_TEST: "https://auth.bmwgroup.net/auth/oauth2/realms/root/realms/intranetb2x/authorize?response_type=id_token&scope=openid%20groups&redirect_uri=https://techradar.bmwgroup.net&client_id=4cd21d37-9758-4127-b7e3-c79955e520e0&nonce=",


  // Role definitions
  EXCEPTION_RADARS: ['Fokusprojekt-SP21 Risikoradar.csv'], // Update this list with restricted/exceptional radars.
  LOGIN_USER_ROLE: [],
  RADAR_ROLES: {// Add roles for every radar accessible on Techradar application
    'Group IT Radar.csv': ['admin_view', 'trendradar_view'],
    'ItO BM5 Trend Radar.csv': ['admin_view', 'trendradar_view'],
    'Research and Development Radar.csv': ['admin_view', 'trendradar_view'],
    'Production Systems Radar.csv': ['admin_view', 'trendradar_view'],
    'jc technologies.csv': ['admin_view', 'trendradar_view'],
    'Financial Services Radar.csv': ['admin_view', 'trendradar_view'],
    'Mobility And Energy Services.csv': ['admin_view', 'trendradar_view'],
    'Design Trends Radar.csv': ['admin_view', 'trendradar_view'],
    'Fokusprojekt-SP21 Risikoradar.csv': ['riskradar_view']
  }, 
  
  // Filter information
  FILTERS: {
    BLIPFilters: [],
    BLIPSCurrentFilter: [],
    BLIPSNewFilter: []
  },

  // Quadrant / Ring Informatilon
  RADAR_DATE: '',
  RADAR_VISITORS: '',
  RADAR_HITS: '',
  COLUMN_NAMES: [],
  RING_NAMES: [],
  QUADRANT_NAMES: [],
  MIN_NUM_QUADRANTS: 4,
  MAX_NUM_QUADRANTS: 14,
  MAX_NUM_RINGS: 5,
  CENTER_OFFSET: 30,
  // "size" of a quadrant in degrees - 90 for 4 quadrants, 60 for 6 "quadrants" etc.
  // Must correspond to the number of quadrants as inherently defined by quadrant names above
  // (QUADRANT_NAMES)
  QUADRANT_SIZE: 45,
  RADAR_SIZE: 0,
  MARKER_LABEL: 'high priority',

  // Degrees of polar coordinate rotation to alleviate flipped y axis in computer graphics
  // but keeping clockwise rotation (trigonometric unit circle is anti-clockwise
  // Will be used in conjunction with the quadrant size, whether static or computed (see above)
  POLAR_OFFSET: -90,

  // Radar Visualization Constants
  IDEAL_BLIP_WIDTH: (window.innerWidth >= 720) ? 30 : 20,
  MIN_BLIP_WIDTH: 12,
  ANIMATION_DURATION: 1000,
  QUADRANT_SPACING_LINE_WIDTH: 0,
  QUADRANT_COLOR_RING_WIDTH: 25,

  // Text displayed in the radar footer
  // eslint-disable-next-line no-multi-str
  FOOTER_TEXT: 'This radar is a cross-departmental crowdsourcing project\
  <br>To contribute join this <a href= "https://cc-github.bmwgroup.net/tim" target="_blank"> organization</a>\
  <br>For feeback get in\
  <a href="mailto:IT-Innovation@list.bmw.com?subject=Feedback Techradar&body=Thanks Guys, the TechRadar is AWESOME!">contact</a>'

}

module.exports = Definitions
