import redrawFullRadar from './redrawFullRadar'

function createHomeLink(pageElement) {
    if (pageElement.select('.home-link').empty()) {
      pageElement.insert('div', 'div#alternative-buttons')
        .html('Back to Radar home')
        .classed('home-link', true)
        .classed('selected', true)
        .on('click', redrawFullRadar)
    }
  }

export default createHomeLink