const GLOBS_RADAR = require('../globals')
const d3 = require('d3')
const populateDeadArray = require('../util/populateDeadArray')
const togglePrintTheme = require('../togglePanelFunctions/togglePrintTheme')
const plotBlipNames = require('../visibilityChecks/checkLabelVisibility')
const createLabels = require('../plotting/createLabels')
const plotBlips = require('../plotting/plotBlips')

/**
 * Recreates labels.
 * Refreshes blips, in case the ring text has changed.
 * Currently only called when the refresh labels button is pressed
 */
const refreshLabel = () => {
  const svg = GLOBS_RADAR.svg

  const toggleSettings = GLOBS_RADAR.toggleSettings
  removeLabels(svg)
  if (toggleSettings.needToRedrawBlips) {
    populateDeadArray()
    redrawBlips(svg)
    toggleSettings.needToRedrawBlips = false
  }

  //const labelFont = document.getElementById('slider_blip').value ? document.getElementById('slider_blip').value : 11 // Blip Labels
  //createLabels(labelFont)
  createLabels(15)

  if (toggleSettings.showBlipNames) {
    plotBlipNames()
  } else {
    svg.selectAll('.blip-labels').attr('visibility', 'hidden')
  }

  if (toggleSettings.printMode) {
    togglePrintTheme()
    togglePrintTheme()
  }
}

function removeLabels (svg) {
  svg.selectAll('.blip-labels').remove()
}

function redrawBlips (svg) {
  svg.selectAll('.blip-link').remove()
  d3.selectAll('.blip-ul').remove()
  d3.selectAll('.ring-name').remove()

  blipCoordinates = new Map()
  let label_array = []
  let anchor_array = []
  const iter = GLOBS_RADAR.quadrantGroupGlobal.keys()
  _.each(GLOBS_RADAR.quadrantsGlobal, function (quadrant, i) {
    const quadrantGroup = iter.next().value
    let blipCoordinatesQuadrantDict
    [blipCoordinatesQuadrantDict, partial_label_array, partial_anchor_array] = plotBlips(quadrantGroup, quadrant)
    blipCoordinates.set(quadrantGroup, blipCoordinatesQuadrantDict) // BLIPNAMES
    label_array.push(...partial_label_array)
    anchor_array.push(...partial_anchor_array)
  })
  GLOBS_RADAR.label_array = label_array
  GLOBS_RADAR.anchor_array = anchor_array
}

module.exports = refreshLabel
