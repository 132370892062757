const Ring = function (name, order, radius) {
  var self = {}

  self.name = function () {
    return name
  }

  self.order = function () {
    return order
  }

  self.radius = function () {
    return radius
  }

  return self
}

module.exports = Ring
