/* eslint no-constant-condition: "off" */
const d3 = require('d3')
const Tabletop = require('tabletop')
const _ = {
  map: require('lodash/map'),
  uniqBy: require('lodash/uniqBy'),
  capitalize: require('lodash/capitalize'),
  each: require('lodash/each')
}

// Internet Explorer < 12 and Android Support
// if (!String.prototype.endsWith) {
//   String.prototype.endsWith = function(searchString, position) {
//       var subjectString = this.toString();
//       if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
//         position = subjectString.length;
//       }
//       position -= searchString.length;
//       var lastIndex = subjectString.indexOf(searchString, position);
//       return lastIndex !== -1 && lastIndex === position;
//   };
// }

const GLOBS = require('../models/globals')
const InputSanitizer = require('./inputSanitizer')
const Radar = require('../models/radar')
const Quadrant = require('../models/quadrant')
const Ring = require('../models/ring')
const Blip = require('../models/blip')
import GraphingRadar from '../graphing/radar'
const QueryParams = require('./queryParamProcessor')
const MalformedDataError = require('../exceptions/malformedDataError')
const SheetNotFoundError = require('../exceptions/sheetNotFoundError')
const ContentValidator = require('./contentValidator')
const Sheet = require('./sheet')
const ExceptionMessages = require('./exceptionMessages')
const GoogleAuth = require('./googleAuth')

const refreshLabels = require('../graphing/radar/togglePanelFunctions/refreshLabels')

const quad =[];

let currentQuadFileName = [];

var createBlips = function (data, rings, dimensions) {

  
    const map_qu = new Map();
    const map_ri = new Map();

    dimensions.forEach(function(dimension) {
      map_qu.set(dimension.dimensionName, true);
      GLOBS.QUADRANT_NAMES.push(dimension.dimensionName);
    })
    
    rings.forEach(function(ring) {
      map_ri.set(ring.ringName, true);
      GLOBS.RING_NAMES.push(ring.ringName.toUpperCase());
    })

    // for (const item of data) {
    //   if(!map_qu.has(item.quadrant)){
    //     map_qu.set(item.quadrant, true);
    //     GLOBS.QUADRANT_NAMES.push(item.quadrant);
    //   }
    //   if(!map_ri.has(item.ring)){
    //     map_ri.set(item.ring, true);
    //     GLOBS.RING_NAMES.push(item.ring.toUpperCase());
    //   }
    // }

    var quadcheck =  GLOBS.QUADRANT_NAMES.map(value => value.toLowerCase());
    // read unique radar rings and quadrants from csv
    if(quad.length > 0){
      for (const item of quad) {
        if(!quadcheck.includes(item.toLowerCase())){
          map_qu.set(item, true);
          GLOBS.QUADRANT_NAMES.push(item);
          
        }
      }
    }


    // quadcheck =  GLOBS.QUADRANT_NAMES.map(value => value.toLowerCase());

    // // read unique radar rings and quadrants from csv
    // if(quad.length > 0){
    //   for (const item of quad) {
    //     if(!quadcheck.includes(item.toLowerCase())){
    //       map_qu.set(item, true);
    //       GLOBS.QUADRANT_NAMES.push(item);
          
    //     }
    //   }
    // }
    if (quad.length > 0){
      quadSort = quad.map(value => value.toLowerCase())
      // // sort the quadrant order based on yml file
      GLOBS.QUADRANT_NAMES.sort(function(a, b) {
        return quadSort.indexOf(a.toLowerCase()) - quadSort.indexOf(b.toLowerCase());
      });
    }

    //TODO: find different solution than sorting to cope with ring order
    GLOBS.RING_NAMES = GLOBS.RING_NAMES.sort((a, b) => a - b)
    // Hack to sort Productive to first ring position if included
    var ringNameFirstpos = 'PRODUCTIVE'
    var temp = []
    if (GLOBS.RING_NAMES.indexOf(ringNameFirstpos) >= 0){
      temp = arrayRemove(GLOBS.RING_NAMES,ringNameFirstpos)
      GLOBS.RING_NAMES = [ringNameFirstpos]
      temp.forEach(function (name, i) {
        GLOBS.RING_NAMES.push(name)
      })
    }

    // Sort Quadrant Names for reproducibility
    // Use the Quadrants.csv file to assert order.  
    // Missing Quadrants found in Radar are added to the end - unsorted.

    if (quad.length > 0){
      let midNames = 0;
      if (GLOBS.QUADRANT_NAMES.length%2 == 0){
        midNames = (GLOBS.QUADRANT_NAMES.length/2)+1;
      }
      else{
        midNames = (GLOBS.QUADRANT_NAMES.length/2);
      }

      for (let index = 1; index < midNames; index++) {
        let element = GLOBS.QUADRANT_NAMES[index];
        let end = 0 + GLOBS.QUADRANT_NAMES.length- index;

        GLOBS.QUADRANT_NAMES[index] = GLOBS.QUADRANT_NAMES[GLOBS.QUADRANT_NAMES.length-index];
        GLOBS.QUADRANT_NAMES[end] = element;  
      }   
    }
    
    // Hack to sort unwanted Quadrant name to last position
    var quadrantNameLastpos='Focus Area Of Department'
    if (GLOBS.QUADRANT_NAMES.indexOf(quadrantNameLastpos) >= 0){
      GLOBS.QUADRANT_NAMES = arrayRemove(GLOBS.QUADRANT_NAMES,quadrantNameLastpos)
      GLOBS.QUADRANT_NAMES.push(quadrantNameLastpos)
    }
    // console.log('QUADRANT_NAMES: ', GLOBS.QUADRANT_NAMES)
    GLOBS.QUADRANT_SIZE=360/GLOBS.QUADRANT_NAMES.length


    // GLOBS.COLUMN_NAMES=columnNames
    
    var blips = _.map(data, new InputSanitizer().sanitize)

    // fix weird order problem
    GLOBS.QUADRANT_NAMES = [GLOBS.QUADRANT_NAMES[0], ...(GLOBS.QUADRANT_NAMES.slice(1).reverse())];

    // strip .csv extension
    plotRadar('tech radar', blips, 'Test', [], '', rings, dimensions)

    // lower or no timeout messes up the blip label rendering, don't ask me why -_-
    setTimeout(refreshLabels, 100);
  
}

const plotRadar = function (title, blips, currentRadarName, metadata, filter, ringsData, dimensions) {
  document.title = title
  d3.selectAll('.loading').remove()

  var rings = []
  var ringMap = {}

  // create static Ring map as per global definition
  var ringMap = {}  // a hash map mapping a ring's name to a Ring instance
  var rings = []    // array of Ring instances - used in the Radar model
  _.each(GLOBS.RING_NAMES, function (ringName, i) {
    var ringData = ringsData.find(r => r.ringName.toUpperCase() === ringName)
    var r = new Ring(ringName, i, ringData ? ringData.ringRadius : 1)
    ringMap[ringName] = r
    rings.push(r)
  })

  var quadrants = {}

  // fill quadrants dict with actual quadrant names
  _.each(GLOBS.QUADRANT_NAMES, function (name, i) {
    const qua = dimensions.find(d => d.dimensionName=== name)
    var q = new Quadrant(name, qua ? qua.color : '', qua ? qua.pcPath : '', i)
    quadrants[name] = q
  })



  // fill quadrants dictionary with quadrant and blip objects
  _.each(blips, function (blip) { 
   
    let filters = []
    if(blip.filter){
      filters = blip.filter.split("|")
      filters.forEach(filter => {
        filter = filter.trim().toUpperCase()
        if (!filter =='' && !GLOBS.FILTERS.BLIPFilters.includes(filter)){
          GLOBS.FILTERS.BLIPFilters.push(filter)
        }
      })
    }

    if (!quadrants[blip.quadrant]) {
      quadrants[blip.quadrant] = new Quadrant(_.capitalize(blip.quadrant))
    }

    if (ringMap[blip.ring.toUpperCase()]) {
      quadrants[blip.quadrant].add(new Blip(blip.id, blip.name, ringMap[blip.ring.toUpperCase()], 
        'aaa', //blip.type.toLowerCase(), 
        blip.description, blip.marker, blip.relevance, blip.speed, blip.knowhow, blip.filter, blip.location))
      
    }
  })

  // add quadrants to radar object
  var radar = new Radar()
  // GLOBS.RADAR_DATE = blips[0].date

  _.each(quadrants, function (quadrant) {
    radar.addQuadrant(quadrant)
  })

  // if (alternativeRadars !== undefined || true) {
  //   alternativeRadars.forEach(function (sheetName) {
  //     radar.addAlternative(sheetName)
  //   })
  // }

  if (currentRadarName !== undefined || true) {
    radar.setCurrentSheet(currentRadarName)
  }

  // Calculate Radar Size
  // Modifies general radar size, both in landing page and in quadrant view
  var size = window.innerWidth;
  GLOBS.RADAR_SIZE=size
  new GraphingRadar(size, radar, metadata).init().plot()
}

const GoogleSheet = function (sheetReference, sheetName) {
  var self = {}
  self.build = function () {
    var sheet = new Sheet(sheetReference)
    sheet.validate(function (error) {
      if (!error) {
        Tabletop.init({
          key: sheet.id,
          callback: createBlips
        })
        return
      }

      if (error instanceof SheetNotFoundError) {
        plotErrorMessage(error)
        return
      }
      self.authenticate(false)
    })

    function createBlips (__, tabletop) {
      try {
        if (!sheetName) {
          sheetName = tabletop.foundSheetNames[0]
        }
        var columnNames = tabletop.sheets(sheetName).columnNames
        
        var contentValidator = new ContentValidator(columnNames)
        contentValidator.verifyContent()
        contentValidator.verifyHeaders()
        
        var all = tabletop.sheets(sheetName).all()
        var blips = _.map(all, new InputSanitizer().sanitize)        
        
        plotRadar(tabletop.googleSheetName + ' - ' + sheetName, blips, sheetName, tabletop.foundSheetNames)
      } catch (exception) {
        plotErrorMessage(exception)
      }
    }
  }
  
  
  
  function createBlipsForProtectedSheet (documentTitle, values, sheetNames) {
  
    if (!sheetName) {
      sheetName = sheetNames[0]
    }
    values.forEach(function (value) {
      var contentValidator = new ContentValidator(values[0])
      contentValidator.verifyContent()
      contentValidator.verifyHeaders()
    })

    const all = values
    const header = all.shift()
    var blips = _.map(all, blip => new InputSanitizer().sanitizeForProtectedSheet(blip, header))
    plotRadar(documentTitle + ' - ' + sheetName, blips, sheetName, sheetNames)
  }

  self.authenticate = function (force = false, callback) {
    GoogleAuth.loadGoogle(function (e) {
      GoogleAuth.login(_ => {
        var sheet = new Sheet(sheetReference)
        sheet.processSheetResponse(sheetName, createBlipsForProtectedSheet, error => {
          if (error.status === 403) {
            plotUnauthorizedErrorMessage()
          } else {
            plotErrorMessage(error)
          }
        })
        if (callback) { callback() }
      }, force)
    })
  }

  self.init = function () {
    plotLoading()
    return self
  }

  return self
}

function setDocumentTitle () {
  document.title = 'Technology Radar'
}

function plotLoading (content) {
  content = d3.select('body')
    .append('div')
    .attr('class', 'loading')
    .append('div')
    .attr('class', 'input-sheet')

  setDocumentTitle()

  var bannerText = '<h1>Building your radar...</h1><p>Your Technology Radar will be available in just a few seconds</p>'
  plotBanner(content, bannerText)
  plotFooter(content)
}

function plotFooter (content) {
  content
    .append('div')
    .attr('id', 'footer')
    .append('div')
    .attr('class', 'footer-content')
    .append('p')
    // LANDING PAGE FOOTER TEXT
    .html(GLOBS.FOOTER_TEXT)
}

function plotBanner (content, text) {
  content.append('div')
    .attr('class', 'input-sheet__banner')
    .html(text)
}

function plotErrorMessage (exception) {
  return;
  // var message = 'Oops! It seems like there are some problems with loading your data. '

  // var content = d3.select('body')
  //   .append('div')
  //   .attr('class', 'input-sheet')
  // setDocumentTitle()

  // var bannerText = '<div><h1>Your Radar could not be built</h1><p>something has gone wrongybongy</p></div>'

  // plotBanner(content, bannerText)

  // d3.selectAll('.loading').remove()
  // message = "Oops! We can't find the Sheet you've entered "
  // var faqMessage = 'is the hosted file accessible?'
  // if (exception instanceof MalformedDataError) {
  //   message = message.concat(exception.message)
  // } else if (exception instanceof SheetNotFoundError) {
  //   message = exception.message
  // } else {
  //   console.error(exception)
  // }

  // const container = content.append('div').attr('class', 'error-container')
  // var errorContainer = container.append('div')
  //   .attr('class', 'error-container__message')
  // errorContainer.append('div').append('p')
  //   .html(message)
  // errorContainer.append('div').append('p')
  //   .html(faqMessage)

  // var homePageURL = window.location.protocol + '//' + window.location.hostname
  // homePageURL += (window.location.port === '' ? '' : ':' + window.location.port)
  // var homePage = '<a href=' + homePageURL + '>GO BACK</a>'

  // errorContainer.append('div').append('p')
  //   .html(homePage)

  // plotFooter(content)
}

function plotUnauthorizedErrorMessage () {
  var content = d3.select('body')
    .append('div')
    .attr('class', 'input-sheet')
  setDocumentTitle()

  var bannerText = '<div><h1>TODO : Some Error Banner Text</h1></div>' // TODO : define Banner text

  plotBanner(content, bannerText)

  d3.selectAll('.loading').remove()
  const currentUser = GoogleAuth.geEmail()
  let homePageURL = window.location.protocol + '//' + window.location.hostname
  homePageURL += (window.location.port === '' ? '' : ':' + window.location.port)
  const goBack = '<a href=' + homePageURL + '>GO BACK</a>'
  const message = `<strong>Oops!</strong> Looks like you are accessing this sheet using <b>${currentUser}</b>, which does not have permission.Try switching to another account.`

  const container = content.append('div').attr('class', 'error-container')

  const errorContainer = container.append('div')
    .attr('class', 'error-container__message')

  errorContainer.append('div').append('p')
    .attr('class', 'error-title')
    .html(message)

  const button = errorContainer.append('button')
    .attr('class', 'button switch-account-button')
    .text('SWITCH ACCOUNT')

  errorContainer.append('div').append('p')
    .attr('class', 'error-subtitle')
    .html(`or ${goBack} to try a different sheet.`)

  button.on('click', _ => {
    var queryString = window.location.href.match(/sheetId(.*)/)
    var queryParams = queryString ? QueryParams(queryString[0]) : {}
    const sheet = GoogleSheet(queryParams.sheetId, queryParams.sheetName)
    sheet.authenticate(true, _ => {
      content.remove()
    })
  })
}

function arrayRemove(arr, value) {
  return arr.filter(function(ele){
    return ele != value;
  });
}

const RadarFactory = function () {
  var self = {};
  self.plot = function (data, rings, dimensions) {
    createBlips(data, rings, dimensions)
    // plotRadar('tech radar', data, 'test', [], '')
  }

  return self
}
export default RadarFactory
