/**
 * commonly used math expressions across the radar
 */
const mathUtil = {
    center: () => {
        const size = require('../globals').size
        return Math.round(size / 2)
    },
    toRadian: (angleInDegrees) => {
        return Math.PI * angleInDegrees / 180
    },
    toDegree: (angleInRadian) => {
        return angleInRadian * 180 / Math.PI
    },
}
module.exports = mathUtil