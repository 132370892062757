const d3 = require('d3')
import d3tip from 'd3-tip'
const _ = require('lodash/core')
const $ = require('jquery')
require('jquery-ui/ui/widgets/autocomplete')

const RingCalculator = require('../util/ringCalculator')
const QueryParams = require('../util/queryParamProcessor')
const MergeFlowApi = require('../util/mergeFlowApi')

import selectQuadrant from './radar/pointer-events/selectQuadrant'

const populateDeadArray = require('./radar/util/populateDeadArray')

import createLabels from './radar/plotting/createLabels'
import plotBlips from './radar/plotting/plotBlips'
import plotQuadrant from './radar/plotting/plotQuadrant'
import createTogglesPanel from './radar/plotting/createTogglesPanel'

import createQuadrantButtons from './radar/plotting/createQuadrantButtons'
import redrawFullRadar from './radar/plotting/redrawFullRadar'
import checkToggleVisibility from './radar/visibilityChecks/checkToggleVisibility'

const mathUtil = require('./radar/util/mathUtil')

const GLOBS = require('../models/globals')
const RADAR_GLOBS = require('./radar/globals')

var mergeFlowApi = new MergeFlowApi()

/**
 * idk
 * @param {*} size 
 * @param {*} radar 
 * @param {*} metadata 
 * @param {*} searchString 
 */
const Radar = function (size, radar, metadata, searchString) {
  //random globals that I never used
  var radarElement, header, alternativeDiv
  //no clue what these are for either
  var blipCoordinates = new Map()

  var self = {}

  /**
   * Set globals based on input, create tooltip for hovering blips
   */
  self.init = function () {
    radarElement = d3.select('body').append('div').attr('id', 'radar')
    RADAR_GLOBS.size = size
    RADAR_GLOBS.ringCalculator = new RingCalculator(radar.rings().length + 1, mathUtil.center(), radar.rings())
    RADAR_GLOBS.mergeFlowApi = mergeFlowApi
    let tip = d3tip().attr('class', 'd3-tip').html(function (text) {
      return text
    })
  
    tip.direction(function () {
      if (d3.select('.quadrant-table.selected').node()) {
        var selectedQuadrant = d3.select('.quadrant-table.selected')
        if (selectedQuadrant.classed('first') || selectedQuadrant.classed('fourth')) { return 'ne' } else { return 'nw' }
      }
      return 'n'
    })
    RADAR_GLOBS.tip = tip
    
    RADAR_GLOBS.searchString = searchString
    return self
  }

  /**
   * Create the entire page
   */
  self.plot = function () {
    //no idea what these variables are for
    var alternatives, currentSheet

    //set variables needed to create the page
    RADAR_GLOBS.ringsGlobal = radar.rings()
    let quadrantsGlobal = radar.quadrants()
    RADAR_GLOBS.quadrantsGlobal = quadrantsGlobal

    alternatives = radar.getAlternatives()
    currentSheet = radar.getCurrentSheet()

    //Create Header Portion
    var header = plotRadarHeader()    
    
    if (alternatives.length) { // If there are no alternatives, hide them
      plotAlternativeRadars(alternatives, currentSheet)
    }

    matchButtonsToQuads(quadrantsGlobal) // Match quad input
    createQuadrantButtons(radarElement, quadrantsGlobal, header) // Create colored button row at top
    
    //Create the SVG Radar
    console.log('Create the SVG Radar')
    RADAR_GLOBS.svg = radarElement.append('svg').call(RADAR_GLOBS.tip).attr('id', 'radar-plot').attr('viewBox', `0 0 ${size} ${size + 20}`);

    createTogglesPanel(quadrantsGlobal, header, metadata) // Create control panel for SVG

    var legendKeys = new Set()
    let blipToSearch = {}

    //Fill the SVG
    _.each(quadrantsGlobal, function (quadrant, i) {
      //Plot Quadrant
      var [quadrantGroup, partial_legendKeys] = plotQuadrant(quadrant)

      //idk, not my code
      legendKeys = new Set([...legendKeys, ...partial_legendKeys])
      RADAR_GLOBS.legendKeys = legendKeys
      var j = 0
      for (let key of Array.from(legendKeys).sort()) {
        RADAR_GLOBS.legendKeysMap.set(key, j)
        j += 1
      }
      RADAR_GLOBS.quadrantGroupGlobal.set(quadrantGroup, quadrant)
      
      // ACT, THINK, WATCH
      plotRingNames(quadrantGroup, (window.innerWidth >= 720) ? 20 : 12, quadrant)
      if (i === 0) {
        populateDeadArray() // populate dead space on radar
      }

      // Plot Blips
      let blipCoordinatesQuadrantDict
      let partial_label_array, partial_anchor_array
      let searchedBlip
      [blipCoordinatesQuadrantDict, partial_label_array, partial_anchor_array, searchedBlip] = plotBlips(quadrantGroup, quadrant)

      // Set variables to be used later
      if (!_.isEmpty(searchedBlip)) blipToSearch = searchedBlip
      blipCoordinates.set(quadrantGroup, blipCoordinatesQuadrantDict) // BLIPNAMES
      RADAR_GLOBS.label_array.push(...partial_label_array)
      RADAR_GLOBS.anchor_array.push(...partial_anchor_array)
    })

    createLabels(15)

    if(RADAR_GLOBS.searchString) findSearchString(blipToSearch)
  }

  /**
   * Changes order of quadrants from 1, 2, 3, 4, ... -> 1, 12, 11, 10,... as radar plots CCW
   * @param {*} quadrants : list of quadrants
   */
  function matchButtonsToQuads(quadrants) {
    quadrants.reverse();
    quadrants.unshift(quadrants.pop());
  }

  //idk
  function plotLines(quadrantGroup, quadrant) {
    // Plots white lines deviding the quadrants
    var startX = size * (1 - (-Math.sin(mathUtil.toRadian(quadrant.startAngle)) + 1) / 2)
    var endX = size * (1 - (-Math.sin(mathUtil.toRadian(quadrant.startAngle - 90)) + 1) / 2)

    var startY = size * (1 - (Math.cos(mathUtil.toRadian(quadrant.startAngle)) + 1) / 2)
    var endY = size * (1 - (Math.cos(mathUtil.toRadian(quadrant.startAngle - 90)) + 1) / 2)

    if (startY > endY) {
      var aux = endY
      endY = startY
      startY = aux
    }

    quadrantGroup.append('line')
      .attr('x1', mathUtil.center()).attr('x2', mathUtil.center())
      .attr('y1', startY - 2).attr('y2', endY + 2)
      .attr('stroke-width', 10)

    quadrantGroup.append('line')
      .attr('x1', endX).attr('y1', mathUtil.center())
      .attr('x2', startX).attr('y2', mathUtil.center())
      .attr('stroke-width', 10)
  }

  //idk
  function plotTexts(quadrantGroup, rings, quadrant) {
    // Plots the Ring Names on the Radar Lines
    rings.forEach(function (ring, i) {
      if (quadrant.order === 'first' || quadrant.order === 'eighth') {
        quadrantGroup.append('text')
          .attr('class', 'line-text')
          .attr('y', mathUtil.center() + 4)
          .attr('x', mathUtil.center() + (RADAR_GLOBS.ringCalculator.getRadius(i) + RADAR_GLOBS.ringCalculator.getRadius(i + 1)) / 2)
          .attr('text-anchor', 'middle')
          .text(ring.name())
      } else {
        quadrantGroup.append('text')
          .attr('class', 'line-text')
          .attr('y', mathUtil.center() + 4)
          .attr('x', mathUtil.center() - (RADAR_GLOBS.ringCalculator.getRadius(i) + RADAR_GLOBS.ringCalculator.getRadius(i + 1)) / 2)
          .attr('text-anchor', 'middle')
          .text(ring.name())
      }
    })
  }

  /**
   * Put ACT, THINK, WATCH on the radar for a specific quadrant group
   * @param {*} quadrantGroup 
   * @param {*} fontSize 
   * @param {*} quadrant 
   */
  function plotRingNames(quadrantGroup, fontSize, quadrant) {
    const rings = RADAR_GLOBS.ringsGlobal
    // Plots the Ring Names on the Radar Lines
    rings.forEach(function (ring, i) {
      quadrantGroup.append('text')
        .attr('class', 'ring-text')
        .attr('y', mathUtil.center() - (RADAR_GLOBS.ringCalculator.getRadius(i) + RADAR_GLOBS.ringCalculator.getRadius(i + 1)) / 2)
        .attr('x', mathUtil.center())
        .attr('text-anchor', 'middle')
        .style('font-size', (fontSize + 'px'))
        .style('fill', 'black')
        .text(ring.name())
        .attr('visibility', 'visible')
        .attr('mask', `url(#mask-${quadrant.order})`)
    })
  }

  /**
   * Plot the top image header
   */
  function plotRadarHeader() {
    header = d3.select('body').insert('header', '#radar')
    return header
  }

  /**
   * Plot the navigation bar right under the header
   * @param {*} group : What to append navbar to
   */
  function plotNavbar(group) {
    let homePageURL = window.location.origin + window.location.pathname
    const nav = group.append('div').attr('class', 'navbar').attr('id', 'navbar').attr('style', 'display: flex; align-items:center;')

    d3.select('#navbar').append('div')
    .classed('search-box', true)
    .append('input')
    .attr('id', 'auto-complete')
    .attr('placeholder', 'Search')
    .classed('search-radar', true)
  }

  /**
   * Plots the footer
   */
  function plotRadarFooter () {
    // FOOTER TEXT
    d3.select('body')
      .append('div')
      .attr('id', 'footer')
      .append('div')
      .attr('class', 'footer-content')
      .append('p')
  }

  //idk
  function constructSheetUrl(sheetName) {
    var noParamUrl = window.location.href.substring(0, window.location.href.indexOf(window.location.search))
    var queryParams = QueryParams(window.location.search.substring(1))
    var sheetUrl = noParamUrl + '?sheetId=' + queryParams.sheetId + '&sheetName=' + encodeURIComponent(sheetName)
    return sheetUrl
  }

  //idk
  function plotAlternativeRadars(alternatives, currentSheet) {
    var alternativeSheetButton = alternativeDiv
      .append('div')
      .classed('multiple-sheet-button-group', true)

    alternativeSheetButton.append('p').text('Choose a sheet to populate radar')
    alternatives.forEach(function (alternative) {
      alternativeSheetButton
        .append('div:a')
        .attr('class', 'first full-view alternative multiple-sheet-button')
        .attr('href', constructSheetUrl(alternative))
        .text(alternative)

      if (alternative === currentSheet) {
        d3.selectAll('.alternative').filter(function () {
          return d3.select(this).text() === alternative
        }).attr('class', 'highlight multiple-sheet-button')
      }
    })
  }

  /**
   * Zooms in on a blip that was searched for.
   * @param {
   *  searchBlipObj: blip,
   *  searchBlipItemDescription: string,
   *  searchBlipOrder: string (first, second,etc.),
   *  searchStartAngle: startAngle
   * } blipToSearch : selected blip based on URL
   */
  function findSearchString(blipToSearch) {
    const blip = blipToSearch.searchBlipObj
    const blipItemDescription = blipToSearch.searchBlipItemDescription
    const order = blipToSearch.searchBlipOrder
    const startAngle = blipToSearch.searchStartAngle

    const isQuadrantSelected = d3.select('div.button.' + order).classed('selected')
    selectQuadrant.bind({}, order, startAngle)()
    const selectedDesc = d3.select('#blip-description-' + blip.number())
    // Blip Description Expanding
    d3.select('.blip-item-description.expanded').node() !== blipItemDescription.node() &&
      d3.select('.blip-item-description.expanded').classed('expanded', true)
    blipItemDescription.classed('expanded', !blipItemDescription.classed('expanded'))
    selectedDesc.classed('expanded', true)
    const group = d3.select('#blip-link-' + blip.number())
    group.attr('opacity', 1.0)
    d3.selectAll('.blip-list-item').classed('highlight', false)
    d3.select('#blip-list-item-' + blip.number()).classed('highlight', true)

    if (GLOBS.USE_MERGEFLOW){
      mergeFlowApi.makeAllApiCalls(blip , selectedDesc)
    }
    if (isQuadrantSelected) {
      RADAR_GLOBS.tip.show(blip.name(), group.node())
    } else {
    // need to account for the animation time associated with selecting a quadrant
    RADAR_GLOBS.tip.hide()

      setTimeout(function () {
        RADAR_GLOBS.tip.show(blip.name(), group.node())
      }, GLOBS.ANIMATION_DURATION)
    }
    RADAR_GLOBS.toggleSettings.zoomedView = true
  }

  return self
}

export default Radar
