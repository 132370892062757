const RADAR_GLOBS = require('../globals')
const center = require('../util/mathUtil').center

/**
 * checks toggle settings to show/hide proper labels
 */
const checkLabelVisibility = () => {     
  const svg = RADAR_GLOBS.svg
  const toggleSettings = RADAR_GLOBS.toggleSettings
    svg.selectAll('.blip-labels').attr('visibility', 'hidden')
    if (toggleSettings.showBlipNames) {
      if (toggleSettings.zoomedView) {
        //zoomed if you want a transition pass in with the data, but that's not possible currently
        // svg.selectAll('.zoomed-labels')
        // .transition()
        // .duration(800)
        // .attr("x", function(d) { return (d.x - d.width/2); })
        // .attr("y", function(d) { return (d.y - d.height); })
        svg.selectAll('.blip-labels.zoomed-labels').attr('visibility', 'visible')
      } else {
        if (toggleSettings.outerLabels) {
          let labelGroup = svg.select('.blip-labels.outer-labels')
          labelGroup.attr('visibility', 'visible')
          svg.attr('viewBox', `${center()-(labelGroup.node().getBBox().width/2)-20} -20 ${labelGroup.node().getBBox().width + 20} ${RADAR_GLOBS.size + 40}`)
          svg.attr('width', '100%')
        } else {
          //normal
          // labels
          // .transition()
          // .duration(800)
          // .attr("x", function(d) { return (d.x - d.width/2); })
          // .attr("y", function(d) { return (d.y - d.height); })

        // links
        //   .transition()
        //   .duration(800)
        //   .attr("x2",function(d) { return (d.x + d.width/50); })
        //   .attr("y2",function(d) { return (d.y - d.height/4); });
          let labelGroup = svg.select('.blip-labels.collision-labels')
          labelGroup.attr('visibility', 'visible')
          svg.attr('viewBox', `${center()-(labelGroup.node().getBBox().width/2)-20} -20 ${labelGroup.node().getBBox().width + 20} ${RADAR_GLOBS.size + 40}`)
          svg.attr('width', '100%')
        }
      }
    }
  }

  module.exports = checkLabelVisibility