//https://github.com/petercollingridge/code-for-blog/blob/master/svg-interaction/draggable/draggable_foreign.svg?short_path=ff99822
/**
 * Enables/disables dragging elements on the svg.
 * Only triggers on objects with class 'draggable'
 * @param {node} trueSvg not d3 selection, actual svg node
 * @param {boolean} bool whether to enable or disable draggability
 */
const toggleDraggable = (trueSvg, bool) => {
    if (bool) {
      trueSvg.addEventListener('mousedown', startDrag);
      trueSvg.addEventListener('mousemove', drag);
      trueSvg.addEventListener('mouseup', endDrag);
      trueSvg.addEventListener('mouseleave', endDrag);
      trueSvg.addEventListener('touchstart', startDrag);
      trueSvg.addEventListener('touchmove', drag);
      trueSvg.addEventListener('touchend', endDrag);
      trueSvg.addEventListener('touchleave', endDrag);
      trueSvg.addEventListener('touchcancel', endDrag);
    } else {
      trueSvg.removeEventListener('mousedown', startDrag);
      trueSvg.removeEventListener('mousemove', drag);
      trueSvg.removeEventListener('mouseup', endDrag);
      trueSvg.removeEventListener('mouseleave', endDrag);
      trueSvg.removeEventListener('touchstart', startDrag);
      trueSvg.removeEventListener('touchmove', drag);
      trueSvg.removeEventListener('touchend', endDrag);
      trueSvg.removeEventListener('touchleave', endDrag);
      trueSvg.removeEventListener('touchcancel', endDrag);
    }
    function getMousePosition(evt) {
      var CTM = trueSvg.getScreenCTM();
      if (evt.touches) { evt = evt.touches[0]; }
      return {
        x: (evt.clientX - CTM.e) / CTM.a,
        y: (evt.clientY - CTM.f) / CTM.d
      };
    }
    var selectedElement, offset, transform;
    function startDrag(evt) {
      if (evt.target.classList.contains('draggable')) {
        selectedElement = evt.target;
        offset = getMousePosition(evt);
        // Make sure the first transform on the element is a translate transform
        var transforms = selectedElement.transform.baseVal;
        if (transforms.length === 0 || transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE) {
          // Create an transform that translates by (0, 0)
          var translate = trueSvg.createSVGTransform();
          translate.setTranslate(0, 0);
          selectedElement.transform.baseVal.insertItemBefore(translate, 0);
        }
        // Get initial translation
        transform = transforms.getItem(0);
        offset.x -= transform.matrix.e;
        offset.y -= transform.matrix.f;
      }
    }
    function drag(evt) {
      if (selectedElement) {
        evt.preventDefault();
        var coord = getMousePosition(evt);
        transform.setTranslate(coord.x - offset.x, coord.y - offset.y);
      }
    }
    function endDrag(evt) {
      selectedElement = false;
    }
  }
  module.exports = toggleDraggable