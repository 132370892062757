const d3 = require('d3')
const GLOBS = require('../globals')
const GLOBS_RADAR = require('../globals')
const selectQuadrant = require('../pointer-events/selectQuadrant')

const searchBlip = (_e, ui) => {
    const tip = GLOBS.tip
    // Blip Description Expanding
    const { value, label, blip, quadrant } = ui.item
    if(!label.includes("_@")){
      const isQuadrantSelected = d3.select('div.button.' + quadrant.order).classed('selected')
      selectQuadrant.bind({}, quadrant.order, quadrant.startAngle)()
      const selectedDesc = d3.select('#blip-description-' + blip.number())
      
      d3.select('.blip-item-description.expanded').node() !== selectedDesc.node() &&
        d3.select('.blip-item-description.expanded').classed('expanded', false)
      selectedDesc.classed('expanded', true)
      d3.selectAll('g.blip-link').attr('opacity', 0.3)
      const group = d3.select('#blip-link-' + blip.number())
      group.attr('opacity', 1.0)
      d3.selectAll('.blip-list-item').classed('highlight', false)
      d3.select('#blip-list-item-' + blip.number()).classed('highlight', true)
      // MergeFlow API Call and Chart Drawing
      if (GLOBS.USE_MERGEFLOW){
        GLOBS_RADAR.mergeFlowApi.makeAllApiCalls(blip,selectedDesc)
      }
      if (isQuadrantSelected) {
        tip.show(blip.name(), group.node())
      } else {
      // need to account for the animation time associated with selecting a quadrant
        tip.hide()

        setTimeout(function () {
          tip.show(blip.name(), group.node())
        }, GLOBS.ANIMATION_DURATION)
      }
  }
  else{
    location.replace(window.location.origin + window.location.pathname+ '?sheetId=%2Fcsv%2F' +label.split("_@")[1]+".csv&q="+value)
  }
  }

  module.exports = searchBlip