const MalformedDataError = require('../exceptions/malformedDataError')
const ExceptionMessages = require('../util/exceptionMessages')
const GLOBS = require('../models/globals')

const _ = {
  map: require('lodash/map'),
  uniqBy: require('lodash/uniqBy'),
  sortBy: require('lodash/sortBy'),
  each: require('lodash/each')
}

const Radar = function () {
  var self, quadrants, blipNumber, addingQuadrant, alternatives, currentSheetName

  blipNumber = 0

  var orderId = ['first', 'second','third', 'fourth', 'fifth', 'sixth', 'seventh',
  'eighth','ninth','tenth','eleventh','twelvth','thirteenth','fourteenth','fifteenth']
  var newAngle=GLOBS.QUADRANT_SIZE
  var quadrants=[]
  var index=0
  
  // Dynamic Quadrant Angle Placement
  _.each(GLOBS.QUADRANT_NAMES, function (){
    quadrants.push({"order" : orderId[index], "startAngle": newAngle})
    newAngle-=GLOBS.QUADRANT_SIZE
      index ++
  }) 

  addingQuadrant = 0
  alternatives = []
  currentSheetName = ''
  self = {}

  function setNumbers (blips) {
    blips.forEach(function (blip) {
      blip.setNumber(++blipNumber)
    })
  }

  self.addAlternative = function (sheetName) {
    alternatives.push(sheetName)
  }

  self.getAlternatives = function () {
    return alternatives
  }

  self.setCurrentSheet = function (sheetName) {
    currentSheetName = sheetName
  }

  self.getCurrentSheet = function () {
    return currentSheetName
  }

  self.addQuadrant = function (quadrant) {
    if (addingQuadrant > GLOBS.MAX_NUM_QUADRANTS) { throw new MalformedDataError(ExceptionMessages.TOO_MANY_QUADRANTS) }
    quadrants[addingQuadrant].quadrant = quadrant
    setNumbers(quadrant.blips())
    addingQuadrant++
  }

  function allQuadrants () {
    //if (addingQuadrant < GLOBS.MIN_NUM_QUADRANTS) { throw new MalformedDataError(ExceptionMessages.TOO_FEW_QUADRANTS) }
    return _.map(quadrants, 'quadrant')
  }

  function allBlips () {
    return allQuadrants().reduce(function (blips, quadrant) {
      return blips.concat(quadrant.blips())
    }, [])
  }

  self.rings = function () {
    return _.sortBy(_.map(_.uniqBy(allBlips(), function (blip) {
      // console.log('blip ring: ', blip.ring())
      return blip.ring().name()
    }), function (blip) {
      return blip.ring()
    }), function (ring) {
      return ring.order()
    })
  }

  self.quadrants = function () {
    return quadrants
  }

  return self
}

module.exports = Radar
