const RADAR_GLOBS = require('../globals')
const getIteration = require('../util/orderNumberUtil').getIteration
// if printmode then change arc text to black color
    // if(printMode){
    //   $(`#radar svg#radar-plot .arc-text`).css('fill', 'black');
    // }
/**
 * Changes the radar into printing colors
 */
const togglePrintTheme = () => {
  const toggleSettings = RADAR_GLOBS.toggleSettings
    toggleSettings.printMode = !toggleSettings.printMode;
    var btns = document.getElementsByClassName('quadrant-btn');
    var quadCount = document.getElementsByClassName('quadrant-group');

    if (toggleSettings.printMode) {
      printThemeOn(btns, quadCount);
    } else {
      printThemeOff(btns, quadCount);
    }
  }

function printThemeOn(btns, quadCount) {
  const svg = RADAR_GLOBS.svg
  const toggleSettings = RADAR_GLOBS.toggleSettings
    var quadList = []
    var selectorList = []
    $(`#radar svg#radar-plot .arc-text`).css('fill', 'white');
    $(`#radar svg#radar-plot text.blip-text`).css('fill', 'white');

    if (toggleSettings.zoomedView) {
      for (var btnIndex=0; btnIndex<btns.length; btnIndex++) {
          if (btns[btnIndex].classList.contains('selected')) {
            var btnClass = 'print-btn-' + (btnIndex+1);
            btns[btnIndex].classList.add(btnClass);
          }
      }
    }
    else {
      for (var btnIndex2=0; btnIndex2<btns.length; btnIndex2++) {
        var btnClass2 = 'print-btn-' + (btnIndex2+1);
        btns[btnIndex2].classList.add(btnClass2);
      }
    }
    for (var quadIndex=0; quadIndex<quadCount.length; quadIndex++) {
      var quadClass = 'print-quad-' + (quadIndex+1);
      quadList.push(quadClass);
      selectorList.push(getIteration()[quadIndex].name);
    }
    selectorList = selectorList.reverse();
    selectorList.unshift(selectorList.pop());

    for (var listIndex=0; listIndex<quadList.length; listIndex++) {
      var circleSelector = `circle.${selectorList[listIndex]}`;
      var pathSelector = `path.${selectorList[listIndex]}`;
      var polygonSelector = `polygon.${selectorList[listIndex]}`;
      $(`#radar svg#radar-plot ${circleSelector}, #radar svg#radar-plot ${pathSelector}, #radar svg#radar-plot ${polygonSelector}`).addClass(quadList[listIndex]);
      svg.selectAll(`.outer-labels .quadrant-group-${selectorList[listIndex]}.blip-textContainer text`).classed(quadList[listIndex], true);
    }
  }

  function printThemeOff(btns, quadCount) {
    const svg = RADAR_GLOBS.svg
  const toggleSettings = RADAR_GLOBS.toggleSettings
    var quadList = []
    var selectorList = []
    $(`#radar svg#radar-plot .arc-text`).css('fill', 'white');
    $(`#radar svg#radar-plot text.blip-text`).css('fill', 'white');
    for (var btnIndex=0; btnIndex<btns.length; btnIndex++) {
      var btnClass = 'print-btn-' + (btnIndex+1);
      btns[btnIndex].classList.remove(btnClass);
    }
    for (var quadIndex=0; quadIndex<quadCount.length; quadIndex++) {
      var quadClass = 'print-quad-' + (quadIndex+1);
      quadList.push(quadClass);
      selectorList.push(getIteration()[quadIndex].name);
    }
    selectorList = selectorList.reverse();
    selectorList.unshift(selectorList.pop());

    for (var listIndex=0; listIndex<quadList.length; listIndex++) {
      var circleSelector = `circle.${selectorList[listIndex]}`;
      var pathSelector = `path.${selectorList[listIndex]}`;
      var ploygonSelector = `polygon.${selectorList[listIndex]}`;
      $(`#radar svg#radar-plot ${circleSelector}, #radar svg#radar-plot ${pathSelector}, #radar svg#radar-plot ${ploygonSelector}`).removeClass(quadList[listIndex]);
      svg.selectAll(`.outer-labels .quadrant-group-${selectorList[listIndex]}.blip-textContainer text`).classed(quadList[listIndex], false);
    }
  }

  module.exports = togglePrintTheme