const d3 = require('d3')
const createlegendShapes = require('../svg-paths/legendShapes')
const RADAR_GLOBS = require('../globals')

function drawLegend (order) {
    const size = RADAR_GLOBS.size
    const legendKeys = RADAR_GLOBS.legendKeys
    const svg = RADAR_GLOBS.svg
    const legendShapes = createlegendShapes(svg)
    removeRadarLegend()

    var container = RADAR_GLOBS.svg.append('g')
      .attr('class', 'legend legend' + '-' + order)

    var x = 10
    var y = 10

    // Legend Placement
    if (RADAR_GLOBS.quadrantOrientationX === 'left') {
      x = size / 1.5
    }
    if (RADAR_GLOBS.quadrantOrientationX === 'right') {
      x = size * 0.25
    }
    if (RADAR_GLOBS.quadrantOrientationY === 'up') {
      y = size / 1.2
    }
    if (RADAR_GLOBS.quadrantOrientationY === 'down') {
      y = size / 1.15
    }

    d3.select('.legend')
      .attr('class', 'legend legend-' + order)
      .transition()
      .style('visibility', 'visible')

    // draw legend

    var i = 0
    for (let key of Array.from(legendKeys).sort()) {
      // if there are more legend keys than shapes, repeat with the last shape
      legendShapes[i < legendShapes.length ? i : 0](x, y + i * 20, container);
      container
        .append('text')
        .attr('x', x + 15)
        .attr('y', y + 5 + i * 20)
        .attr('font-size', '0.8em')
        .text(key)
      i += 1
    }
  }

  function removeRadarLegend() {
    d3.select('.legend').remove()
  }

  module.exports = drawLegend