const RADAR_GLOBS = require('../globals')
const plotBlipNames = require('../visibilityChecks/checkLabelVisibility')
const checkToggleVisibility = require('../visibilityChecks/checkToggleVisibility')

/**
 * Called when labels are turned on or off
 */
const toggleBlipNames = () => {
    const toggleSettings = RADAR_GLOBS.toggleSettings
    const svg = RADAR_GLOBS.svg
    const size = RADAR_GLOBS.size

    toggleSettings.showBlipNames = !toggleSettings.showBlipNames
    checkToggleVisibility()
    if (toggleSettings.showBlipNames) {
      let currBlipSize = $("#radar svg#radar-plot .blip-textContainer div").css('font-size')
      let currRingSize = $("#radar svg#radar-plot .ring-text").css('font-size')
      let currQuadSize = $("#radar svg#radar-plot .arc-text").css('font-size')
      $('input#slider_blip').val(parseFloat(currBlipSize))
      $('input#slider_ring').val(parseFloat(currRingSize))
      $('input#slider_quadrant').val(parseFloat(currQuadSize))
      if (toggleSettings.zoomedView) {
        svg.selectAll('.zoomed-labels').attr('visibility', 'visible')
      } else {
        plotBlipNames() // checkLabelVisibility
      }
    } else {
      if (toggleSettings.zoomedView) {
        svg.selectAll('.zoomed-labels').attr('visibility', 'hidden')
      } else {
        svg.selectAll('.blip-labels').attr('visibility', 'hidden')
        svg.attr('viewBox', `0 0 ${size} ${size+20}`)
        svg.attr('width', size)
      }
    }
  }

  module.exports = toggleBlipNames