import $ from 'jquery'
const d3 = require('d3')
const RADAR_GLOBS = require('../globals')

/**
 * Checks togglesettings and sets the visibility of all of the toggle options
 */
const checkToggleVisibility = () => {
    const toggleSettings = RADAR_GLOBS.toggleSettings
    // open all pointers. Disable them based on conditions
    $('div#sliderGrid').css('pointer-events', 'auto').css('opacity', '1')
    $('.sliderDiv_ring').css('pointer-events', 'auto').css('opacity', '1')
    $('div#toggleDiv_outsideLabels').css('pointer-events', 'auto').css('opacity', '1')
    $('div#toggleDragDrop').css('pointer-events', 'auto').css('opacity', '1')
    $('div#rerender_labels').css('pointer-events', 'auto').css('opacity', '1')
    if (toggleSettings.showBlipNames) {
        if (toggleSettings.zoomedView) { // custom setup
            $('.sliderDiv_ring').css('pointer-events', 'none').css('opacity', '0.4')
            $('div#toggleDiv_outsideLabels').css('pointer-events', 'none').css('opacity', '0.4')
            if(toggleSettings.dragDropOn){
                $('div#sliderGrid').css('pointer-events', 'none').css('opacity', '0.4')
                $('.sliderDiv_ring').css('pointer-events', 'none')
            }
        } 
        else{
            if(toggleSettings.outerLabels && toggleSettings.dragDropOn){ 
                // if both are on and not in zoomed view then on outerLabels and off dragdrop
                toggleSettings.dragDropOn = false;
                var dragDropOn = document.getElementById("dragDropInputCheck"); dragDropOn.checked = false;
            }
            if(toggleSettings.outerLabels) {
                $('div#toggleDragDrop').css('pointer-events', 'none').css('opacity', '0.4')
            }
            if(toggleSettings.dragDropOn){
                $('div#toggleDiv_outsideLabels').css('pointer-events', 'none').css('opacity', '0.4')
                $('div#sliderGrid').css('pointer-events', 'none').css('opacity', '0.4')
                $('.sliderDiv_ring').css('pointer-events', 'none')
                $('div#rerender_labels').css('pointer-events', 'none').css('opacity', '0.4')
            }

        }
    } else {
        $('.sliderDiv_ring').css('pointer-events', 'none')
        $('div#toggleDiv_outsideLabels').css('pointer-events', 'none').css('opacity', '0.4')
        $('div#sliderGrid').css('pointer-events', 'none').css('opacity', '0.4')
        $('div#rerender_labels').css('pointer-events', 'none').css('opacity', '0.4')
        $('div#toggleDragDrop').css('pointer-events', 'none').css('opacity', '0.4')
    }
}

export default checkToggleVisibility