const GLOBS = require('../../models/globals')
const Definitions = {
  svg: null, // d3 selection of the SVG
  size: null, // width of the radar svg
  label_array: [], // array with info on how to labels
  anchor_array: [], // array of coordinates of blips
  dead_array: [], // dead space array/off-limits locations for label creation/blip plotting
  filters: GLOBS.FILTERS,
  mergeFlowApi: null,
  
  toggleSettings: { // settings of the toggles
    showBlipNames: true,
    dragDropOn: false,
    outerLabels: false,
    zoomedView: false,
    needToRedrawBlips: false,
    printMode: false,
  },
  ringCalculator: null, // util calculator that helps plot the ring
  tip: null, // tooltip
  legendKeys: new Set(), // legent
  legendKeysMap: new Map(),
  quadrantsGlobal: null, // list of quadrants
  quadrantGroupGlobal: new Map(), // list of quadrants (again?)
  ringsGlobal: null, // list of rings
  searchString: null, // searched string

  //not sure what these two actually do
  quadrantOrientationX: '', // orientation of the quadrant when zoomed in
  quadrantOrientationY: '', // orientation of quadrant when zoomed in
}

module.exports = Definitions
