require("./stylesheets/base.scss");
import factory from "./util/factory";
import $ from 'jquery'

let url, locationTemp;
if (self.frameElement && self.frameElement.tagName === "IFRAME") {
  const frame = window.parent.frames["radar"].location.href;
  locationTemp = window.parent.frames["radar"].location
  url = new URL(frame);

} else {
  url = new URL(location.href);
  locationTemp = location
}

// const reqpath = process.env.VUE_APP_RADAR_TYPE;
$.getJSON(locationTemp.origin + '/radar.json?timestamp=' + Date.now(), function(radarData) {
  const radar = radarData.radar
  const techs = radarData.trend.map((t) => {
    console.log(t.location)
    return {
      ...t,
      quadrant: t.dimensionName,
      ring: t.status,
    };
  });
  factory().plot(techs, radar.ringDTOList, radar.dimensionDTOList);
})
