const GLOBS = require('../../../models/globals')
const GLOBS_RADAR = require('../globals')
const labeler = require('../util/labeler')
const mouseoverQuadrant = require('../pointer-events/mouseoverQuadrant')
const mouseoutQuadrant = require('../pointer-events/mouseoutQuadrant')
const center = require('../util/mathUtil').center

/**
 * Creates 3 types of labels: 
 *  collision labels (on the radar, tries to avoid collisions), 
 *  outer labels (around outsie of radar),
 *  and zoomed labels (labels that show up when zoomed)
 * @param {number} labelFont font size of the labels
 */
const createLabels = function (labelFont) {
  const svg = GLOBS_RADAR.svg
  const anchor_array = GLOBS_RADAR.anchor_array
  const label_array = GLOBS_RADAR.label_array
  const dead_array = GLOBS_RADAR.dead_array

    //organize label_array, anchor_array into quadrants
    const blipGroupings = {}
    const anchorGroupings = {}
    label_array.forEach((l,i) => {
      blipGroupings[l.quadrant] = blipGroupings[l.quadrant] ? [...blipGroupings[l.quadrant], l] : [l]
      anchorGroupings[l.quadrant] = anchorGroupings[l.quadrant] ? [...anchorGroupings[l.quadrant], anchor_array[i]] : [anchor_array[i]]
    })
    
    makeOuterLabels(blipGroupings, labelFont)
    makeCollisionLabels(labelFont)
    makeZoomedCollisionLabels(blipGroupings, anchorGroupings, labelFont)

    /**
     * Creates labels on the outside
     * @param {order: labelArray of that quadrant-group} blipGroupings Object with order (first, second, etc.) 
     * as key and array of label_array entries as value
     * @param {number} fontSize font size
     */
    function makeOuterLabels(blipGroupings, fontSize) {

        let outerLabelGroup = svg.append('g')
            .attr('class', 'blip-labels outer-labels')
    
        //let theta = Math.asin((center()-fontSize-2)/center())
        let theta = Math.asin((1))
        let middle = true
        let flipped = false;
        for (i in blipGroupings) {
          if (!blipGroupings.hasOwnProperty(i)) {return}
          const labels = blipGroupings[i] //labels for a group
    
          outerLabelGroup.append('g').attr('class', 'quadrant-group-' + labels[0].quadrant)
            .selectAll(".quad-label-" + labels[0].quadrant)
            .data(labels)
            .enter().append('g')
            .attr("class", function(d) {return 'quadrant-group-' + d.quadrant + ' blip-textContainer'})
            .on('mouseover', mouseoverQuadrant.bind({}, labels[0].quadrant))
            .on('mouseout', mouseoutQuadrant.bind({}, labels[0].quadrant))
            //.on('click', selectQuadrant.bind({}, svg, labels[0].quadrant, labels[0].angle, GLOBS.size))
            .append("text")
            .attr("class", 'blip-name ' + labels[0].quadrant)
            .attr('text-anchor', 'start')
            .text(function(d) { return d.number + '. ' + d.name; })
            .attr("x", function(d) { return (center()); })
            .attr("y", function(d) { return (center()); })
            .attr("fill", "black")
            .attr("font-size", `${fontSize}px`)
            .attr("font-weight", "bold")
            .attr("alignment-baseline", "middle")
            .attr('transform', (d) => {
              let dx = Math.cos(theta) * (center()+3);
              let dy = Math.sin(theta) * (center()+3);
              if (isNaN(dy) /*|| dy > center()+3*/ ) {
                middle = true
                flipped = true
                theta = Math.asin(1)
                dx = Math.cos(theta) * (center()+3);
                dy = Math.sin(theta) * (center()+3);
              }
              d.flipped = flipped
              if (middle) {
                d.flipped = 'middle'
                middle = false
                dy += dy > 0 ? 5 : -5
              }
              theta = dy > 0 ? Math.asin((dy-fontSize*1.3)/center()) : Math.asin((dy-fontSize)/center())
              if (isNaN(theta)) theta = Math.asin((dy-11)/center())
              return flipped ? `translate(${-(dx + 5)}, ${dy})` : `translate(${dx + 5}, ${-dy})`// we want the y axis to start at the top
            })
            .attr('text-anchor',(d) => {
              if (d.flipped == 'middle') return 'middle'
              return d.flipped ? 'end':'start'
            })
        }
      }
    
      //https://github.com/tinker10/D3-Labeler/blob/gh-pages/index.html
      //http://tinker10.github.io/D3-Labeler/
      //http://vis.berkeley.edu/courses/cs294-10-fa13/wiki/images/5/55/FP_EvanWang_paper.pdf
      /**
       * Creates anchors on top of blips and calculates collisions between labels. Tries to minimize based on util/labeler.js logic
       * @param {number} fontSize 
       */
      function makeCollisionLabels (fontSize) {
        const offset = 4
    
        let collisionLabelGroup = svg.append('g')
        .attr('class', 'blip-labels collision-labels')
    
        // Draw anchors
        anchors = collisionLabelGroup.selectAll(".dot")
          .data(anchor_array)
          .enter().append("circle")
          .attr("class", function(d) { return 'quadrant-group-' + d.quadrant + ' dot' } )
          .attr("r", function(d) { return (d.r); })
          .attr("cx", function(d) { return (d.x); })
          .attr("cy", function(d) { return (d.y); })
          .attr("fill", 'green')
          .attr("opacity", 0)
          .attr('pointer-events', 'none');
    
        // Draw labels
        labels = collisionLabelGroup.selectAll(".label")
          .data(label_array)
          .enter()
          .append("foreignObject")
          .attr("class", function(d) {return 'quadrant-group-' + d.quadrant + ' blip-textContainer'})
          .attr("x", function(d) { return (d.x); })
          .attr("y", function(d) { return (d.y); })
          .attr("height", "100%")
          .attr("width", fontSize * 13) // setting where to wrap
          .attr('pointer-events', 'none')
          .style("display", 'inline-block')
    
        labels.append("xhtml:div")
          .attr("class", 'blip-name')
          .html((d) => `${d.name}`)
          .style("font-size", `${fontSize}px`)
          //.style("color", "black")
    
        // Size of each label
        var index = 0;
        labels.each(function() {
          const label = this.childNodes[0]
          this.setAttribute('width', label.offsetWidth)
          this.setAttribute('height', label.offsetHeight)
          label_array[index].width = label.offsetWidth;
          label_array[index].height = label.offsetHeight;
          this.setAttribute('x', this.getAttribute('x') - label.offsetWidth/2) // translate to align with middle
          this.setAttribute('y', this.getAttribute('y') - label.offsetHeight) // trnaslate to align with middle
          index += 1;
        });
    
        // Draw data points
        circ = collisionLabelGroup.selectAll(".circ")
          .data(label_array)
          .enter().append("circle")
          .attr("class", function(d) {return 'quadrant-group-' + d.quadrant + ' circ'})
          .attr("r", 5.0)
          .attr("cx", function(d) { return (d.x); })
          .attr("cy", function(d) { return (d.y - offset); })
          .attr("fill", 'red')
          .attr("opacity",0)
          .attr('pointer-events', 'none');
    
        // Draw links
        // links = collisionLabelGroup.selectAll(".link")
        //   .data(label_array)
        //   .enter()
        //   .append("line")
        //   .attr("class", function(d) {return 'quadrant-group-' + d.quadrant + ' link'})
        //   .attr("x1", function(d) { return (d.x); })
        //   .attr("y1", function(d) { return (d.y); })
        //   .attr("x2", function(d) { return (d.x); })
        //   .attr("y2", function(d) { return (d.y); })
        //   .attr("stroke-width", 0.6)
        //   .attr("stroke", "pink").attr('pointer-events', 'none');
        labeler()
          .label(label_array)
          .anchor(anchor_array)
          .dead(dead_array)
          .width(svg.node().getBBox().width)
          .height(svg.node().getBBox().height)
          .ring({r: center(GLOBS.size)*.9, t: center()})
          .fontSize(fontSize)
          .start(200); //number of iterations for simulated annealing. Lower = faster
      }
    
      /**
       * Same logic as collision labels just on a quadrant by quadrant basis for selection purposes
       * @param {same as above} blipGroupings 
       * @param {Same as above, but for anchors} anchorGroupings 
       * @param {font size} fontSize 
       */
      function makeZoomedCollisionLabels(blipGroupings, anchorGroupings, fontSize) {
        const offset = 4
        for (i in blipGroupings) {
          if (!blipGroupings.hasOwnProperty(i)) {return}
          const labels = blipGroupings[i] //labels for a group
          const anchors = anchorGroupings[i] // anchors for a quadrant
          const zoomedQuadrantLabels = svg.select('.quadrant-group.quadrant-group-' + labels[0].quadrant)
            .append('g')
            .attr('class', 'blip-labels zoomed-labels')
            .attr('pointer-events', 'none')
          // Draw anchors
          madeAnchors = zoomedQuadrantLabels.selectAll(".dot")
            .data(anchors)
            .enter().append("circle")
            .attr("class", function(d) { return 'quadrant-group-' + d.quadrant + ' dot' } )
            .attr("r", function(d) { return (d.r); })
            .attr("cx", function(d) { return (d.x); })
            .attr("cy", function(d) { return (d.y); })
            .attr("fill", 'green')
            .attr("opacity", 0)
            .attr('pointer-events', 'none');
          
          // Draw labels
          madeLabels = zoomedQuadrantLabels.selectAll(".labell")
            .data(labels)
            .enter()
            .append("foreignObject")
            .attr("class", function(d) {return 'quadrant-group-' + d.quadrant + ' blip-textContainer'})
            .attr("x", function(d) { return (d.x); })
            .attr("y", function(d) { return (d.y); })
            .attr("height", "100%")
            .attr("width", fontSize * 13 + 'px') // setting where to wrap
            .attr('pointer-events', 'none')
            .style("display", 'inline-block')
          
          madeLabels.append("xhtml:div")
            .attr("class", 'blip-name')
            .html((d) => `${d.name}`)
            .style("font-size", `${fontSize}px`)
            //.style("color", "white")
    
          // Size of each label
          var index = 0;
          madeLabels.each(function() {
            const label = this.childNodes[0]
            this.setAttribute('width', label.offsetWidth)
            this.setAttribute('height', label.offsetHeight)
            labels[index].width = label.offsetWidth;
            labels[index].height = label.offsetHeight;
            this.setAttribute('x', this.getAttribute('x') - label.offsetWidth/2) // translate to align with middle
            this.setAttribute('y', this.getAttribute('y') - label.offsetHeight) // trnaslate to align with middle
            index += 1;
          });
        
        // Draw data points
          circ = zoomedQuadrantLabels.selectAll(".circ")
            .data(labels)
            .enter().append("circle")
            .attr("class", function(d) {return 'quadrant-group-' + d.quadrant + ' circ'})
            .attr("r", 5.0)
            .attr("cx", function(d) { return (d.x); })
            .attr("cy", function(d) { return (d.y - offset); })
            .attr("fill", 'red')
            .attr("opacity",0)
            .attr('pointer-events', 'none');
    
          // Draw links
          // links = zoomedQuadrantLabels.selectAll(".link")
          //   .data(labels)
          //   .enter()
          //   .append("line")
          //   .attr("class", function(d) {return 'quadrant-group-' + d.quadrant + ' link'})
          //   .attr("x1", function(d) { return (d.x); })
          //   .attr("y1", function(d) { return (d.y); })
          //   .attr("x2", function(d) { return (d.x); })
          //   .attr("y2", function(d) { return (d.y); })
          //   .attr("stroke-width", 0.6)
          //   .attr("stroke", "pink").attr('pointer-events', 'none');
    
          labeler()
            .label(labels)
            .anchor(anchors)
            .dead([])
            .fontSize(fontSize)
            .width(zoomedQuadrantLabels.node().getBBox().width)
            .height(zoomedQuadrantLabels.node().getBBox().height)
            .start(100); //number of iterations for simulated annealing. Lower = faster
          }
      }
}

module.exports = createLabels