const RADAR_GLOBS = require('../globals')
import checkToggleVisibility from '../visibilityChecks/checkToggleVisibility'
const toggleDraggable = require('../pointer-events/toggleDraggable')

/**
 * Called when the drag drop toggle is pressed
 * Enables pointer events on foreign objects and swaps the dragDropOn bool, which auto returns from normal select quadrant pointer events 
 */
const toggleDragDrop = () =>{
    const svg = RADAR_GLOBS.svg
    const toggleSettings = RADAR_GLOBS.toggleSettings

    toggleSettings.dragDropOn = !toggleSettings.dragDropOn
    checkToggleVisibility()
    toggleDraggable(svg._groups[0][0], toggleSettings.dragDropOn)
    if(toggleSettings.dragDropOn){
      if (toggleSettings.zoomedView) {
        svg.selectAll('.zoomed-labels foreignObject').attr('pointer-events', 'auto').classed('draggable', true)
      } else {
        svg.selectAll('.collision-labels foreignObject').attr('pointer-events', 'auto').classed('draggable', true)
      }
    }
    else{
      svg.selectAll('.zoomed-labels foreignObject').attr('pointer-events', 'none').classed('draggable', false)
      svg.selectAll('.collision-labels foreignObject').attr('pointer-events', 'none').classed('draggable', false)
    }    
  }
export default toggleDragDrop