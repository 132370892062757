const d3 = require('d3')
const d3cloud = require('d3-cloud')

const MergeFlowApi = function () {
  const mergeFlowMethods = ['writeTicker', 'drawChart', 'drawWordCloud']
  self.makeAllApiCalls = function (blip, blipItemDescription) {
    for (var i = 0; i < mergeFlowMethods.length; i++) {
      if (!blip.apiResponses().get(i)) {
        window[mergeFlowMethods[i]](blip.name(), blipItemDescription)
        blip.setApiResponses(i, true)
      }
    }
  }

  self.writeTicker = function (queryString, blip) {
    var today = new Date()

    var url = window.location.origin + window.location.pathname + 'csv/' + document.title.split(' ').join('_') + '-getDocumentsFile.json'
    // Read the data
    d3.json(url,
      // Callback
      function (d) {
        // format data
        if (d[queryString] !== undefined) {
          blip.selectAll('p').append('div')
            .html('<br><strong>Recent Blogposts:</strong>')
          var div = blip.selectAll('p').append('div')

          var text = ''
          for (let [key, value] of Object.entries(d[queryString].Documents)) {
            var date = d3.timeParse('%Y-%m-%d')(value.Date.slice(0, 10))
            var timeDiff = Math.floor((today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24))
            var text = text + '<li><a href= "' + value.Url + '">' + value.Title + '</a> ' + timeDiff + ' days ago'
          }
          // add text to div
          div.append('html')
            .style('font-size', '0.8em')
            .html(text)
        }
      })
  }

  // self.drawWordCloud = function (queryString, blip) {
  //   var url = window.location.origin + window.location.pathname + 'csv/' + document.title.split(' ').join('_') + '-getTagCloudFile.json'

  //   // Read the data
  //   d3.json(url,
  //     // Callback
  //     function (d) {
  //       if (d[queryString] !== undefined) {
  //         // set the dimensions and margins of the graph
  //         var margin = { top: 0, right: 50, bottom: 0, left: 0 }
  //         var width = document.getElementById(blip.attr('id')).clientWidth - margin.right - margin.left
  //         var height = width / 4

  //         // colors
  //         var fill = d3.scaleOrdinal(d3.schemeCategory10)

  //         // Title
  //         var title = blip.selectAll('p').append('div')
  //           .html('<br><strong>Related Technologies:</strong>')

  //         // append the svg object to the body of the blip
  //         var svg = blip.selectAll('p').append('svg')
  //           .attr('width', width + margin.left + margin.right)
  //           .attr('height', height + margin.top + margin.bottom)
  //           .append('g')
  //           .attr('transform',
  //             'translate(' + margin.left + ',' + margin.top + ')')
  //         data = []
  //         // format data
  //         for (let [key, value] of Object.entries(d[queryString].Keywords)) {
  //           data.push({
  //             'word': value.Keyword,
  //             'size': parseFloat(value.Weight) * 25 + 12
  //           })
  //         }

  //         // This function takes the output of 'layout' above and draw the words
  //         // Wordcloud features that are THE SAME from one word to the other can be here
  //         const draw = function(words) {
  //           svg
  //             .append('g')
  //             .attr('transform', 'translate(' + layout.size()[0] / 2 + ',' + layout.size()[1] / 2 + ')')
  //             .selectAll('text')
  //             .data(words)
  //             .enter().append('text')
  //             .style('font-size', function (d) { return d.size })
  //             .style('fill', function (d, i) { return fill(i) })
  //             .attr('text-anchor', 'middle')
  //             .style('font-family', 'Impact')
  //             .attr('transform', function (d) {
  //               return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')'
  //             })
  //             .text(function (d) { return d.text })
  //         }

  //         // Constructs a new cloud layout instance. It run an algorithm to find the position of words that suits your requirements
  //         // Wordcloud features that are different from one word to the other must be here
  //         var layout = d3cloud()
  //           .size([width, height])
  //           .words(data.map(function (d) { return { text: d.word, size: d.size } }))
  //           .padding(5) // space between words
  //           .font('Impact')
  //           .fontSize(function (d) { return d.size }) // font size of words
  //           .rotate(function () { return ~~(Math.random()) }) // rotation, necessary even set to zero
  //           .on('end', draw)
  //         layout.start()
          
  //       }
  //     })
  // }

  self.drawChart = function (queryString, blip) {
    // inspired by: https://www.d3-graph-gallery.com/graph/connectedscatter_basic.html

    var legendKeys = ['Activity Rate']
    var colors = ['#69b3a2', 'orange']

    // create apiCall urls
    var urls = []

    var url = window.location.origin + window.location.pathname + 'csv/' + document.title.split(' ').join('_') + '-getDocumentDistOverTimeFile.json'
    urls.push(url)

    // set the dimensions and margins of the graph
    var margin = { top: 10, right: 50, bottom: 20, left: 40 }
    var width = document.getElementById(blip.attr('id')).clientWidth - margin.right - margin.left
    var height = width / 3

    // append the svg object to the body of the blip

    // blip.selectAll('p').append('div')
    // .html('<br><strong>Activity rate over years *:</strong>')




    // Queue all ApiCall Responses
    // var queue = d3.queue()
    // urls.forEach(function (url) {
    //   queue.defer(d3.json, url)
    // })
    // Dequeue Responses
    d3.json(url,
      function (d) {
        var datas = []; var values = []

        var data = []
        if (d[queryString] !== undefined) {
          blip.selectAll('p').append('div')
            .html('<br><strong>Activity rate over years *:</strong>')
          var svg = blip.selectAll('p')
            .append('svg')
            .attr('class', 'distribution')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform',
              'translate(' + margin.left + ',' + margin.top + ')')
          d[queryString].Distribution.forEach(value => {
            data.push({
              'date': d3.timeParse('%Y-%m-%d')(value.TimeBin.slice(0, 10)),
              'value': parseInt(value.DocumentCount, 10)
            })
            values.push(parseInt(value.DocumentCount, 10))
          })
          datas.push(data)
          // Axes
          // X axis --> date format
          var x = d3.scaleTime()
            .domain(d3.extent(datas[0], function (d) { return d.date }))
            .range([ 0, width ])
          svg.append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(x))
          // Y axis -> linear values
          var y = d3.scaleLinear()
            .domain([0, Math.max.apply(Math, values)])
            .range([ height, 0 ])
          svg.append('g')
            .call(d3.axisLeft(y))
          // Grid
          // X gridlines
          svg.append('g')
            .attr('class', 'grid')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(x).ticks(5)
              .tickSize(-height)
              .tickFormat(''))
          // Y gridlines
          svg.append('g')
            .attr('class', 'grid')
            .call(d3.axisLeft(y).ticks(10)
              .tickSize(-width)
              .tickFormat(''))

          datas.forEach(function (data, i) {
            // Add the line
            svg.append('path')
              .datum(data)
              .attr('fill', 'none')
              .attr('stroke', colors[i])
              .attr('stroke-width', 1.5)
              .attr('d', d3.line()
                .x(function (d) { return x(d.date) })
                .y(function (d) { return y(d.value) })
              )
            // Add the points
            svg
              .append('g')
              .selectAll('dot')
              .data(data)
              .enter()
              .append('circle')
              .attr('cx', function (d) { return x(d.date) })
              .attr('cy', function (d) { return y(d.value) })
              .attr('r', 2)
              .attr('fill', colors[i])

            // Legend at the end of each line
            svg
              .append('text')
              .attr('font-weight', 'bold')
              .text(legendKeys[i])
              .attr('y', (height - (margin.bottom + margin.top)) + (i * 20))
              .attr('x', width - margin.left - margin.right - 20)
              .style('fill', colors[i])
              .style('font-size', '1em')

            svg.append('text')
              .attr('font-weight', 'bold')
              .text('* based on micro signals from mergeflow e.g. scientific publications, patents')
              .attr('y', (height - (margin.bottom + margin.top)) + (i * 20) + 25)
              .attr('x', width - margin.left - margin.right - 260)
              .style('font-size', '0.7em')
          })
        }
      }
    )
  }
  return self
}
module.exports = MergeFlowApi
