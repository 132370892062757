const Quadrant = function (name, color, pcPath) {
  var self, blips

  self = {}
  blips = []

  self.name = function () {
    return name
  }

  self.color = function () {
    return color
  }

  self.pcPath = function () {
    return pcPath
  }

  self.add = function (newBlips) {
    if (Array.isArray(newBlips)) {
      blips = blips.concat(newBlips)
    } else {
      blips.push(newBlips)
    }
  }

  self.blips = function () {
    return blips.slice(0)
  }

  return self
}

module.exports = Quadrant
