const d3 = require('d3')

const mouseoutQuadrant = function(order) {
    // reset all dimming/highlighting on the segment
    d3.selectAll('.quadrant-group:not(.quadrant-group-' + order + ')').style('opacity', 1)
    d3.selectAll('.quadrant-group:not(.quadrant-group-' + order + ') .blip-link').style('opacity', 1)
    //Reset the label content
    d3.select('body').selectAll('.blip-labels g:not(.quadrant-group-' + order + ')').style('opacity', 1)
    d3.select('body').selectAll('.blip-labels line:not(.-group-' + order + ')').style('opacity', 1)
    d3.selectAll('.button.full-view:not(.' + order+')').style('opacity', 1)
  }

module.exports = mouseoutQuadrant