const d3 = require('d3')
const GLOBS = require('../../../models/globals')
const RADAR_GLOBS = require('../globals')

const mathUtil = require('../util/mathUtil')

import selectQuadrant from '../pointer-events/selectQuadrant'
const mouseoverQuadrant = require('../pointer-events/mouseoverQuadrant')
const mouseoutQuadrant = require('../pointer-events/mouseoutQuadrant')

import createHomeLink from './createHomeLink'
const drawLegend = require('./drawLegend')

const enforcePrintTheme = require('../visibilityChecks/enforcePrintTheme')

const plotQuadrant = (quadrant) => {
    const svg = RADAR_GLOBS.svg
    const ringCalculator = RADAR_GLOBS.ringCalculator
    const toggleSettings = RADAR_GLOBS.toggleSettings
    const rings = RADAR_GLOBS.ringsGlobal

    const legendKeys = new Set()
    // read legend names from blips into a set and map
    var blips = quadrant.quadrant.blips()
    blips.forEach(function (blip, i) {
      if(blip.marker()==1) 
        blip.setType(GLOBS.MARKER_LABEL)

      legendKeys.add(blip.type())
    })

    // Plots the colored circles / quadrants
    var quadrantGroup = svg.append('g')
      .attr('class', 'quadrant-group quadrant-group-' + quadrant.order)
      .on('mouseover', () => {
        if (toggleSettings.dragDropOn) {
          return
        }
        enforcePrintTheme(quadrant.order, 'mouseover')
        mouseoverQuadrant(quadrant.order)
      })
      .on('mouseout', ()=> {
        if (toggleSettings.dragDropOn) {
          return
        }
        enforcePrintTheme(quadrant.order, 'mouseover')
        mouseoutQuadrant(quadrant.order)
      })
      .on('click', () => {
        if (toggleSettings.dragDropOn) {
          return
        }
        toggleSettings.zoomedView = true;
        svg.selectAll('.home-link').classed('selected', false)
        createHomeLink(d3.select('header'))
        selectQuadrant(quadrant.order, quadrant.startAngle)
        // if (svg.select('.legend.legend-' + quadrant.order).empty()) {
        //   drawLegend(quadrant.order)
        // }
        d3.selectAll('header .detail-image').classed('selected', false)
        d3.select('header .detail-image.' + quadrant.order).classed('selected', true)
        if (toggleSettings.showBlipNames) {
          svg.selectAll('.zoomed-labels')
            .attr('visibility', 'visible')
        }
        enforcePrintTheme(quadrant.order, 'select')
      })

    let quadrantTransform = 'translate(' + mathUtil.center() + ', ' + mathUtil.center() + ')';

    // PLot the quadrant
    rings.forEach(function (ring, i) {
      var arc = d3.arc()
        .innerRadius(ringCalculator.getRadius(i))
        .outerRadius(ringCalculator.getRadius(i + 1) - GLOBS.QUADRANT_SPACING_LINE_WIDTH)
        .startAngle(mathUtil.toRadian(quadrant.startAngle))
        .endAngle(mathUtil.toRadian(quadrant.startAngle - GLOBS.QUADRANT_SIZE))

      quadrantGroup.append('path')
        .attr('d', arc)
        .attr('class', 'ring-arc-' + ring.order())
        .attr('transform', quadrantTransform)
    })

    function _addWhiteLine(angle) {
        quadrantGroup.append('path')
            .attr('d', d3.lineRadial()([[0, 0], [
                mathUtil.toRadian(angle),
                ringCalculator.getRadius(rings.length)
            ]]))
            .attr('transform', quadrantTransform)
            .attr('fill', 'none')
            //.attr('stroke', 'white')
            .attr('stroke', 'silver')
            .attr('stroke-width', '1px');
    }
    _addWhiteLine(quadrant.startAngle - GLOBS.QUADRANT_SIZE);
    if (quadrant.startAngle == 0) {
        _addWhiteLine(0);
    }

    quadrantGroup.append('mask')
      .attr('id', 'mask-'+quadrant.order)
      .append('path')
        .attr('d', d3.arc()
          .innerRadius(ringCalculator.getRadius(0))
          .outerRadius(ringCalculator.getRadius(rings.length + 1))
          .startAngle(mathUtil.toRadian(quadrant.startAngle))
          .endAngle(mathUtil.toRadian(quadrant.startAngle - GLOBS.QUADRANT_SIZE)))
        .attr('transform', quadrantTransform)
      .attr('fill', 'white')

    // Plot colored outer most ring
    var arc_outer = d3.arc()
      .innerRadius(ringCalculator.getRadius(rings.length) + GLOBS.QUADRANT_SPACING_LINE_WIDTH * 2)
      .outerRadius(ringCalculator.getRadius(rings.length + 1))
      .startAngle(mathUtil.toRadian(quadrant.startAngle))
      .endAngle(mathUtil.toRadian(quadrant.startAngle - GLOBS.QUADRANT_SIZE))

    quadrantGroup.append('path')
      .attr('id', "arc_outer." + quadrant.order)
      .attr('d', arc_outer)
      .attr('class', quadrant.order)
      .attr('fill', quadrant.quadrant.color())
      .attr('transform', quadrantTransform)

    let fontSize = (GLOBS.RADAR_SIZE / 50);
    let textPos = (ringCalculator.getRadius(rings.length) - ringCalculator.getRadius(rings.length + 1) + fontSize) / 2;
    quadrantGroup.append("text")
      .attr("class", "quadrant-text")
      //.attr("dy", -GLOBS.RADAR_SIZE / 200-3)
      .attr("dy", textPos)
      .append("textPath") //append a textPath to the text element
      .attr("xlink:href", "#arc_outer." + quadrant.order) //place the ID of the path here
      .attr("class", "arc-text")
      .attr("startOffset", "73%")
      .style('font-size', fontSize + 'px')
      .text(quadrant.quadrant.name);

    return [quadrantGroup, legendKeys]
  }

export default plotQuadrant