/**
 * manages conversion between order and number, i.e. 1 -> first
 */
const orderNumberUtil = {
    getIteration: () => {
        return [
            { name: "first", number: 1 },
            { name: "second", number: 2 },
            { name: "third", number: 3 },
            { name: "fourth", number: 4 },
            { name: "fifth", number: 5 },
            { name: "sixth", number: 6 },
            { name: "seventh", number: 7 },
            { name: "eighth", number: 8 },
            { name: "ninth", number: 9 },
            { name: "tenth", number: 10 },
            { name: 'eleventh', number: 11 },
            { name: 'twelvth', number: 12 },
            { name: 'thirteenth', number: 13},
            { name: 'fourteenth', number: 14}
          ];
    },
    orderToNumber: () => {
        return {
            'first': 1,
            'second': 2,
            'third': 3,
            'fourth' :4,
            'fifth': 5,
            'sixth': 6,
            'seventh': 7,
            'eighth': 8,
            'ninth': 9,
            'tenth': 10,
            'eleventh': 11,
            'twelveth': 12,
            'thirteenth': 13,
            'fourteenth': 14
        }
    },
    numberToOrder: () => {
        return {
            1: 'first',
            2: 'second',
            3: 'third',
            4: 'fourth',
            5: 'fifth',
            6: 'sixth',
            7: 'seventh',
            8: 'eighth',
            9: 'ninth',
            10: 'tenth',
            11: 'eleventh',
            12: 'twelveth',
            13: 'thirteenth',
            14: 'fourteenth'
        }
    }
}

module.exports = orderNumberUtil