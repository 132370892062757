import $ from 'jquery'
const RADAR_GLOBS = require('../globals')
const GLOBS = require('../../../models/globals')
const d3 = require('d3')

import dragDropBlipLabels from '../togglePanelFunctions/toggleDragDrop'
const togglePrintTheme = require('../togglePanelFunctions/togglePrintTheme')

const plotBlipNames = require('../visibilityChecks/checkLabelVisibility')
import checkToggleVisibility from '../visibilityChecks/checkToggleVisibility'

// Function to zoom out of zoomed in selected quadrant mode
function redrawFullRadar() {
    const svg = RADAR_GLOBS.svg
    dragDropBlipLabels()
    RADAR_GLOBS.toggleSettings.zoomedView = false;
    dragDropBlipLabels()

    d3.select('body').classed('detail-view', false)
    
    d3.selectAll('text.ring-text').attr('visibility', 'visible');

    if (RADAR_GLOBS.toggleSettings.printMode) {
      togglePrintTheme();
      togglePrintTheme()
    }

    removeHomeLink()
    removeRadarLegend()
    RADAR_GLOBS.tip.hide()
    d3.selectAll('g.blip-link').attr('opacity', 1.0)

    svg.style('left', 0).style('right', 0)

    d3.selectAll('.button')
      .classed('selected', false)
      .classed('full-view', true)
      .style('opacity', 1)

    d3.selectAll('.quadrant-table').classed('selected', false)
    d3.selectAll('.home-link').classed('selected', false)

    d3.selectAll('.quadrant-group')
      .transition()
      .duration(GLOBS.ANIMATION_DURATION)
      .attr('transform', 'scale(1)')

    d3.selectAll('.quadrant-group .blip-link')
      .transition()
      .duration(GLOBS.ANIMATION_DURATION)
      .attr('transform', 'scale(1)')

    d3.selectAll('.quadrant-group')
      .style('pointer-events', 'auto')

    // Delete quadrant image
    d3.selectAll('.detail-image')  
      .classed('selected',false)

    //return labels to normal
    d3.selectAll('.blip-labels')
      .attr('visiblity', 'hidden')
      .attr('pointer-events', 'auto')
      .transition()
      .duration(GLOBS.ANIMATION_DURATION)
      .attr('transform', 'scale(1)')
    d3.selectAll('.zoomed-labels')
      .attr('visibility', 'hidden')
    RADAR_GLOBS.toggleSettings.zoomedView = false;
    checkToggleVisibility()
    if (RADAR_GLOBS.toggleSettings.showBlipNames) {
      plotBlipNames()
     var currRingSize = $("#radar svg#radar-plot .ring-text").css('font-size')
      $('input#slider_ring').val(parseFloat(currRingSize))
    }
    else{
      svg.transition()
          .duration(GLOBS.ANIMATION_DURATION)
          .attr('viewBox', `0 0 ${RADAR_GLOBS.size} ${RADAR_GLOBS.size+20}`)
    }
    $(".blip-ul li").removeClass("expanded")
  }

  function removeHomeLink() {
    d3.select('.home-link').remove()
  }

  function removeRadarLegend() {
    d3.select('.legend').remove()
  }

export default redrawFullRadar