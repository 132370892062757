import $ from 'jquery'
const d3 = require('d3')
const GLOBS = require('../../../models/globals')
const RADAR_GLOBS = require('../globals')

const toggleLabelType = require('../togglePanelFunctions/toggleLabelType')
const toggleDrapDrop = require('../togglePanelFunctions/toggleDragDrop')
const searchBlip = require('../togglePanelFunctions/searchBlip')
const resizeNames = require('../togglePanelFunctions/resizeNames')
const refreshLabels = require('../togglePanelFunctions/refreshLabels')
const toggleBlipNames = require('../togglePanelFunctions/toggleBlipNames')
const togglePrintTheme = require('../togglePanelFunctions/togglePrintTheme')

/**
 * Creates toggles and sliders to configure the labels.
 * @param {*} quadrants 
 * @param {*} group 
 * @param {*} metadata 
 */
const createTogglesPanel = (quadrants, group, metadata) => {
  const togglePanel = d3.select('#togglePanel')

  // GENERAL
  const generalSection = togglePanel.append('fieldset').attr('id', 'generalSection').html('<legend> GENERAL</legend>')
  // USE MERGEFLOW BUTTON
  //const mergeFlowLabel = generalSection.append('div').classed('toggleDiv2', true).attr('id', 'toggleDiv_mergeflow')
 // mergeFlowLabel.append('label').attr('class', 'wide-spacer').text('MergeFlow API')
//  d3.select('#toggleDiv_mergeflow').append('label').attr('class', 'switch left-spacer').attr('id', 'toggle_mergeflow')
//    .append('input').attr('type', 'checkbox')
 // d3.select('#toggle_mergeflow').append('span').attr('class', 'slider round')
 //   .on('click', () => {
      // Mergflow API
   //   GLOBS.USE_MERGEFLOW = !GLOBS.USE_MERGEFLOW
  //  })

  // PRINT THEME CHECK
  const printThemeLabel = generalSection.append('div').classed('toggleDiv2', true).attr('id', 'toggleDiv_print')
  printThemeLabel.append('label').attr('class', 'wide-spacer').text('Print Theme')
  printThemeLabel.append('label').attr('class', 'switch left-spacer').attr('id', 'toggle_print')
    .append('input').attr('type', 'checkbox')
  d3.select('#toggle_print').append('span').attr('class', 'slider round')
    .on('click', () => {
      togglePrintTheme()
  })  

  // TEXT LABELS
  const textSection = togglePanel.append('fieldset').attr('id', 'textSection').html('<legend> TEXT LABELS </legend>')
 // textSection.append('text').text('----- Text Labels -----').attr('style','vertical-align: top;').classed('controlsHeader', true).attr('id', 'controlsHeader')
  // TOGGLE NAMES BUTTON
  const toggleDiv = textSection.append('div').classed('toggleDiv2', true).attr('id', 'toggleDiv_names')
  toggleDiv.append('label').attr('class', 'wide-spacer').text('Show Text')
  const toggleDivControl = toggleDiv.append('div').attr('id', 'toggle_name_control').attr('style', 'display:flex; align-items:center; justify-content:center;')
  toggleDivControl.append('label').attr('class', 'switch left-spacer').attr('id', 'toggle_names')
    .append('input').attr('type', 'checkbox')
  d3.select('#toggle_names').append('span').attr('class', 'slider round')
    .on('click', () => {
      toggleBlipNames()
  })

  // Second line of Divs opens only with Toggle Names

  // TOGGLE OUTSIDE LABELS BUTTON
  const outerLabelsButton = textSection.append('div').classed('toggleDiv2', true).attr('id', 'toggleDiv_outsideLabels')
  outerLabelsButton.append('label').attr('class', 'wide-spacer').text('Text Outside')
  outerLabelsButton.append('label').attr('class', 'switch left-spacer').attr('id', 'toggle_outsideLabels')
    .append('input').attr('type', 'checkbox')
  d3.select('#toggle_outsideLabels').append('span').attr('class', 'slider round')
    .on('click', () => {
      toggleLabelType()
    })

  // ADD TOGGLE BUTTON FOR DRAG AND DROP FUNCTIONALITY
  const dragDropButton = textSection.append('div').classed('toggleDiv2', true).attr('id', 'toggleDragDrop')
  dragDropButton.append('label').attr('class', 'wide-spacer').text('Drag/Drop')
  d3.select('#toggleDragDrop').append('label').attr('class', 'switch left-spacer').attr('id', 'toggle_drag_drop')
    .append('input').attr('type', 'checkbox').attr('id', 'dragDropInputCheck')
  d3.select('#toggle_drag_drop').append('span').attr('class', 'slider round')
    .on('click', () => {
      toggleDrapDrop()
    })
  
  // RESIZING FONT SLIDERS 
  const sliderGrid = textSection.append('div').attr('class', 'toggleDiv2').attr('id', 'sliderGrid')

  sliderGrid.append('text').text('Size').attr('id', 'sizeLabel')
  sliderGrid//.append('div').classed('toggleDiv2', true).attr('id', 'sliderDiv_blip')
    .append('label').attr('class', 'spacer sliderDiv_blip').text('Trend Text')
  sliderGrid.append('input').attr('class', 'rangeSlider sliderDiv_blip').attr('type', 'range').attr('id', 'slider_blip')
    .attr('min', '8').attr('max', '24').attr('step', '1').attr('value', '11')
  d3.select('#slider_blip').on('change', function(){
    resizeNames(this.value, 'blip')
  })
  
  sliderGrid//.append('div').classed('toggleDiv2', true).attr('id', 'sliderDiv_ring')
    .append('label').attr('class', 'spacer sliderDiv_ring').text('Ring Text')
  sliderGrid.append('input').attr('class', 'rangeSlider sliderDiv_ring').attr('type', 'range').attr('id', 'slider_ring')
    .attr('min', '9').attr('max', '30').attr('step', '1').attr('value', '12')
  d3.select('#slider_ring').on('change', function(){
    resizeNames(this.value, 'ring')
  })

  sliderGrid//.append('div').classed('toggleDiv2', true).attr('id', 'sliderDiv_quadrant')
    .append('label').attr('class', 'spacer sliderDiv_quadrant').text('Quadrant Text')
  sliderGrid.append('input').attr('class', 'rangeSlider sliderDiv_quadrant').attr('type', 'range').attr('id', 'slider_quadrant')
    .attr('min', '12').attr('max', '36').attr('step', '1').attr('value', '14')
  d3.select('#slider_quadrant').on('change', function(){
    resizeNames(this.value, 'quadrant')
  })

  //REFRESH BUTTOJN
  const refreshLabel = textSection.append('div').classed('toggleDiv2', true).attr('id', 'rerender_labels')
  refreshLabel.append('label').attr('class', 'wide-spacer').text('Refresh Trends')
  const refreshWrapper = refreshLabel.append('div').attr('id', 'refreshWrapper').attr('class', 'left-spacer')
  refreshWrapper.append('div')
      .attr('id', 'rerender_labels_button').attr('class', 'switch')
    .append('svg')
      .attr('viewBox', '0 0 20 20')
    .append('path')
      .attr('d', "M12.319,5.792L8.836,2.328C8.589,2.08,8.269,2.295,8.269,2.573v1.534C8.115,4.091,7.937,4.084,7.783,4.084c-2.592,0-4.7,2.097-4.7,4.676c0,1.749,0.968,3.337,2.528,4.146c0.352,0.194,0.651-0.257,0.424-0.529c-0.415-0.492-0.643-1.118-0.643-1.762c0-1.514,1.261-2.747,2.787-2.747c0.029,0,0.06,0,0.09,0.002v1.632c0,0.335,0.378,0.435,0.568,0.245l3.483-3.464C12.455,6.147,12.455,5.928,12.319,5.792 M8.938,8.67V7.554c0-0.411-0.528-0.377-0.781-0.377c-1.906,0-3.457,1.542-3.457,3.438c0,0.271,0.033,0.542,0.097,0.805C4.149,10.7,3.775,9.762,3.775,8.76c0-2.197,1.798-3.985,4.008-3.985c0.251,0,0.501,0.023,0.744,0.069c0.212,0.039,0.412-0.124,0.412-0.34v-1.1l2.646,2.633L8.938,8.67z M14.389,7.107c-0.34-0.18-0.662,0.244-0.424,0.529c0.416,0.493,0.644,1.118,0.644,1.762c0,1.515-1.272,2.747-2.798,2.747c-0.029,0-0.061,0-0.089-0.002v-1.631c0-0.354-0.382-0.419-0.558-0.246l-3.482,3.465c-0.136,0.136-0.136,0.355,0,0.49l3.482,3.465c0.189,0.186,0.568,0.096,0.568-0.245v-1.533c0.153,0.016,0.331,0.022,0.484,0.022c2.592,0,4.7-2.098,4.7-4.677C16.917,9.506,15.948,7.917,14.389,7.107 M12.217,15.238c-0.251,0-0.501-0.022-0.743-0.069c-0.212-0.039-0.411,0.125-0.411,0.341v1.101l-2.646-2.634l2.646-2.633v1.116c0,0.174,0.126,0.318,0.295,0.343c0.158,0.024,0.318,0.034,0.486,0.034c1.905,0,3.456-1.542,3.456-3.438c0-0.271-0.032-0.541-0.097-0.804c0.648,0.719,1.022,1.659,1.022,2.66C16.226,13.451,14.428,15.238,12.217,15.238")
      .attr('fill', 'black')
      .attr('stroke', 'black')
      .attr('stroke-width', '.5px')
  const loader = d3.select('#rerender_labels').append('div').attr('class', 'loaderWrapper left-spacer').style('display', 'none')
  loader.append('div')
      .attr('class', 'loader')
  d3.select('#rerender_labels_button').on('click', function(d){
    loader.style('display', 'block')
    refreshWrapper.style('display', 'none')
    setTimeout(() => { 
      refreshLabels() 
    }, 20) //make sure loader has time to load
    setTimeout(() => {
      loader.style('display', 'none')
      refreshWrapper.style('display', 'inline-block')
    }, 20)
  })  

  // Filters CHECK

  if (GLOBS.FILTERS){
    GLOBS.FILTERS.BLIPFilters.sort((a, b) => a - b)
    //probably kick this out to togglePanelFunctions
    const createFilter = function() {
        oldFil = GLOBS.FILTERS.BLIPSCurrentFilter.join()
        newFil = GLOBS.FILTERS.BLIPSNewFilter.join()


        if (oldFil == newFil) {
          return
        }
        else {
          // Set toggleSettings.needToRedrawBlips to TRUE
          RADAR_GLOBS.toggleSettings.needToRedrawBlips = true
          //const SheetInput = require('../../../util/factory')
          
        
          refreshLabels();
          d3.select('#clearFilter').style('display','flex')
          GLOBS.FILTERS.BLIPSCurrentFilter = GLOBS.FILTERS.BLIPSNewFilter.sort((a, b) => a - b)
        }
        
        
      }
      const clearFilter= function() {
        //if(GLOBS.FILTERS.BLIPSNewFilter != [] ||  GLOBS.FILTERS.BLIPSNewFilter.length > 0){
          d3.selectAll('.filter_checkBox').property('checked', false);
          GLOBS.FILTERS.BLIPSNewFilter = []
          // Set toggleSettings.needToRedrawBlips to TRUE
          RADAR_GLOBS.toggleSettings.needToRedrawBlips = true
          //const SheetInput = require('../../../util/factory')
          const refreshLabels = require('../togglePanelFunctions/refreshLabels')
        
          refreshLabels();
        //}
      }

    window.listenerInitialize = function (filter) {
      evt = document.createEvent("HTMLEvents"); //CREATE NEW EVENT WITH TYPE
      evt.initEvent("change", false, true); //THE EXACT EVENT TO FIRE
      
      const data = [...document.querySelectorAll('.filter_checkBox:checked')].map(e => e.value);
      GLOBS.FILTERS.BLIPSNewFilter = data
    }
    //end probably kick this out to toggle panel functions
    

    if(GLOBS.FILTERS.BLIPFilters != undefined && GLOBS.FILTERS.BLIPFilters.length > 0){

       // TEXT LABELS
      const filterSec = togglePanel.append('fieldset').attr('id', 'filterTrendsSection').html('<legend> TREND FILTERS </legend>')
      //filterSec.append('text').classed('controlsHeader', true).attr('id', 'controlsHeader').text('----- Trend Filters -----').attr('style','vertical-align: top;')
      
      const filterSection = filterSec.append('div').classed('filterSection', true).attr('id', 'filterSection')//.attr('style','margin-left: 25px')
      d3.select('#filterSection').append('div').classed('toggleDiv2', true).attr('id','filterHeader')

      // d3.select('#filterHeader').append('svg').attr('id','filter_svg').attr('width','30').attr('height','25').attr('style','vertical-align: bottom;')
      // d3.select('#filter_svg').append('path').attr('d','M518.462,22.82H0l193.159,203.495l-0.014,269.327l132.173-68.37l-0.014-200.957L518.462,22.82z M212.837,463.286	l0.014-244.827L45.846,42.512h426.769L305.611,218.459l0.014,196.832L212.837,463.286z').attr('style','transform: scale(0.04)')
      //d3.select('#filterHeader').append().text('Filter:').attr('style','vertical-align: top;')
      //d3.select('#selectBox').append('div').attr('class', 'overSelect').attr('style','margin-left: 25px')
      //d3.select('#multipleFilterSelection').append('div').attr('id', 'checkBoxes').attr('style','z-index:9999; position: absolute; display: none; background-color: #BCC2C1; border: 1px darkgrey solid; padding: 2px 4px  2px')
      const filters = filterSection.append('div').attr('id', 'filter_container')

      RADAR_GLOBS.filters.BLIPFilters.forEach(filter => {
      filters.append('div').attr('id','div_'+filter).attr('align','left')
      d3.select('#div_'+filter)
        .append('input')
        .attr('style','display: inline-block')
        .attr('class','filter_checkBox')
        .attr('type','checkbox')
        .attr('id',filter)
        .attr('name',filter)
        .attr('value',filter)

      d3.select('#div_'+filter).append('label').attr('style','display: inline-block; font_size: 14px').attr('for', filter).attr('id','label_'+filter).text(filter)
    })

    filterSection.append('div').attr('id','div_Apply').attr('style', 'height: 50px;')
    const applyButton = d3.select('#div_Apply')
      .append('button')
      .attr('style','display: inline-block; border-radius: 4px; margin: 5px; padding: 6px; font-weight:600; box-shadow: 0 8px 10px 0 rgba(0,0,0,0.2), 0 6px 12px 0 rgba(0,0,0,0.19)')
      .attr('class','button')
      .attr('type','button')
      .attr('id','applyFilter')
      .attr('name','applyFilter')
      .attr('value','applyFilter')
      .style('border-radius', '3px')
      .text('Apply')
      .on('click', createFilter) 


    //d3.select('#checkBoxes').append('div').attr('id','div_Clear').attr('align','left')
    
    const clearButton = d3.select('#div_Apply').append('button')
      .attr('style','display: inline-block; border-radius: 4px; float:right; margin: 5px; padding: 6px; font-weight:600; box-shadow: 0 8px 10px 0 rgba(0,0,0,0.2), 0 6px 12px 0 rgba(0,0,0,0.19)')
      .attr('class','button')
      .attr('type','button')
      .attr('id','clearFilter')
      .attr('name','clearFilter')
      .attr('value','clearFilter')
      .style('border-radius', '3px')
      .style('display','none')
      .text('Clear')
    
    const clearLoader = filterSection.append('div')
      .attr('class', 'loader').style('display', 'none')
    d3.select('#clearFilter').on('click', function(d){
      clearLoader.style('display', 'block')
      d3.select('#div_Apply').style('display', 'none')
      setTimeout(() => { 
        clearFilter()
      }, 20) //make sure loader has time to load
      setTimeout(() => {
        clearLoader.style('display', 'none')
        d3.select('#div_Apply').style('display', 'block')
      }, 20)
    })


    d3.selectAll(".filter_checkBox").on('change', window.listenerInitialize)
    }

  }
  
  var currentSearchList=  _.flatten(_.map(quadrants, function (q, i) {
    return _.map(q.quadrant.blips(), function (b) {
        const name = b.name()
        return { label: name, value: name, blip: b, quadrant: q }
    })}))

  $('#auto-complete').autocomplete({
      source: _.filter(metadata.concat(currentSearchList),function (item){ 
        return !item.label.includes("@"+document.title)
      }),
      select: searchBlip.bind({})
    })
  }

export default createTogglesPanel