// const { number } = require("yargs");

const RingCalculator = function (numberOfRings, maxRadius, rings) {
  
  // Ring Size Ratio [0, inner, middle, outer, ...]
  
  // var sequence = [0, 6, 4, 3, 2, 2, 1]
  const sequence = [0, ...rings.map((r) => r.radius())]
  sequence[numberOfRings] = 2;
  rings.map((r) => r.radius())

  const self = {}

  self.sum = function (length) {
    return sequence.slice(0, length + 1).reduce(function (previous, current) {
      return previous + current
    }, 0)
  }

  self.getRadius = function (ring) {
    var total = self.sum(numberOfRings)
    var sum = self.sum(ring)

    return maxRadius * sum / total
  }

  return self
}

module.exports = RingCalculator
