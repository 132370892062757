const GLOBS_RADAR = require('../globals')
const center = require('./mathUtil').center

/**
 * completely updates the dead space array.
 * Currently only accounts for ring text (ACT, THINK, WATCH)
 */
function populateDeadArray() {
    let svg = GLOBS_RADAR.svg
    let ringCalculator = GLOBS_RADAR.ringCalculator
    //populate dead_array
    const ringLabels = svg.selectAll('.quadrant-group-first .ring-text')
    let index = 0;
    let dead_array = []
    ringLabels.each(function() {
      dead_array.push({
        name: this.innerHTML,
        x: center(),
        y: center() - (ringCalculator.getRadius(index) + ringCalculator.getRadius(index + 1)) / 2,
        width: this.getBBox().width,
        height: this.getBBox().height
      })
      index += 1;
    })
    GLOBS_RADAR.dead_array = dead_array
}

module.exports = populateDeadArray