const d3 = require('d3')

const mouseoverQuadrant = function (order) {
    // highlight the selected segment
    d3.select('.quadrant-group-' + order).style('opacity', 1)
    // dim the non-selected segment
    d3.selectAll('.quadrant-group:not(.quadrant-group-' + order + ')').style('opacity', 0.3)
    d3.selectAll('.quadrant-group:not(.quadrant-group-' + order + ') .blip-link').style('opacity', 0)
    // highlight the segment's button
    d3.select('.button.' + order + '.full-view').style('opacity', 1)
    // dim all other buttons
    d3.selectAll('.quadrant-btn.full-view:not(.' + order+')').style('opacity', 0.3)

    //Dim the label content
    d3.select('body').selectAll('.blip-labels .quadrant-group-' + order + ':not(circle').style('opacity', 1)
    d3.select('body').selectAll('.blip-labels g:not(.quadrant-group-' + order + ')').style('opacity', 0.3)
    d3.select('body').selectAll('.blip-labels line:not(.quadrant-group-' + order + ')').style('opacity', 0.3)
  }

  module.exports = mouseoverQuadrant