const RADAR_GLOBS = require('../globals')
const plotBlipNames = require('../visibilityChecks/checkLabelVisibility')
const checkToggleVisibility = require('../visibilityChecks/checkToggleVisibility')

/**
 * moves labels from interior to outside
 */
const toggleLabelType = () => {
  const toggleSettings = RADAR_GLOBS.toggleSettings
    toggleSettings.outerLabels = !toggleSettings.outerLabels
    checkToggleVisibility()
    if (toggleSettings.zoomedView) return
   
    if (toggleSettings.showBlipNames) {
      plotBlipNames()
    }
  }

  module.exports = toggleLabelType