const GLOBS = require('../models/globals')

const ExceptionMessages = {
  TOO_MANY_QUADRANTS: 'There are more than '+GLOBS.MAX_NUM_QUADRANTS+' quadrant names listed in your data. Check the quadrant column for errors.',
  TOO_MANY_RINGS: 'More than '+ GLOBS.MAX_NUM_RINGS +' rings.',
  MISSING_HEADERS: 'Document is missing one or more required headers or they are misspelled. ' +
  'Check that your document contains headers for: ' + GLOBS.COLUMN_NAMES.toString() + '.',
  MISSING_CONTENT: 'Document is missing content.',
  TOO_FEW_QUADRANTS: 'There are less than '+GLOBS.MIN_NUM_QUADRANTS+' quadrant names listed in your data. Check the quadrant column for errors.',
  SHEET_NOT_FOUND: 'Oops! We can’t find the Google Sheet you’ve entered. Can you check the URL?',
  UNAUTHORIZED: 'Seems like you are not authorized to view this Google Sheet. Ask the owner of the referenced Google Spreadsheet to update the permissions.',
}

module.exports = ExceptionMessages
