const RADAR_GLOBS = require('../globals')
const d3 = require('d3')

const getIteration = require('../util/orderNumberUtil').getIteration

/**
 * enforces the print theme during a mouseover/out pointer event. Consider including within those events.
 * Now just enforces button shading during a select event as well.
 * @param {quadrant order (first, second, etc.)} order 
 * @param {'mouseout', 'mouseover', 'select'} event 
 */
const enforcePrintTheme = (order, event) => {
    const toggleSettings = RADAR_GLOBS.toggleSettings
    if (toggleSettings.printMode && toggleSettings.zoomedView) {
        if (event == 'mouseover') {
            var btns = document.getElementsByClassName('quadrant-btn');
            var selectionOptions = getIteration().slice(0, btns.length).reverse();
            selectionOptions.unshift(selectionOptions.pop());
            var selectedIndex = selectionOptions.findIndex(item => item.name == order);
    
            for (var btnIndex=0; btnIndex<btns.length; btnIndex++) {
                if (btnIndex == selectedIndex) {
                    var btnClass = 'print-btn-' + (btnIndex+1);
                    btns[btnIndex].classList.add(btnClass);
                }
            }
        } else if (event == 'mouseout' || event == 'select') {
            var btns = document.getElementsByClassName('quadrant-btn');
            for (var btnIndex=0; btnIndex<btns.length; btnIndex++) {
                if (!btns[btnIndex].classList.contains('selected')) {
                    var btnClass = 'print-btn-' + (btnIndex+1);
                    btns[btnIndex].classList.remove(btnClass);
                }
            }
        }
    }
    if (event == 'select') {
        d3.selectAll('.quadrant-btn.' + order).style('opacity', 1)
        d3.selectAll('.quadrant-btn:not(.' + order+')').style('opacity', 0.3)
    }
}

module.exports = enforcePrintTheme